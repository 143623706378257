import { LoginService } from 'src/app/login/login.service';
import { ServicosService } from './../servicos/servicos.service';
import { Component, OnInit } from '@angular/core';
import { Login } from 'src/app/login/login.model';
import * as moment from 'moment';

import { Filtros } from './ic_express.model';

@Component({
  selector: 'pt-ic-express',
  templateUrl: './ic-express.component.html',
  styleUrls: ['./ic-express.component.css']
})
export class IcExpressComponent implements OnInit {

  loading = true;
  token: string;
  usuarioLogado: Login = {
    codigofun: null,
    nome: null,
    empresa: null,
    grupoAcesso: null,
  };
  listaCubos = [];
  listaCubosTratada = [];
  listaFiltros = [];
  relatorio = [];
  filtros: Filtros[] = [];
  codigocubo = '';
  filename = '';

  csvData = [];
  dados = [];
  selecionados: string[] = [];
  loadingData = false;
  done = false;

  CSVoptions = {
    fieldSeparator: ';',
    quoteStrings: '"',
    decimalseparator: ',',
    headers: [],
    showTitle: true,
    title: 'Relatório Rápido',
    useBom: true,
    removeNewLines: false,
    keys: []
  };

  constructor(
    private loginService: LoginService,
    private servicosService: ServicosService
  ) { }

  ngOnInit(): void {
    this.token = this.loginService.getToken();
    this.getUsuarioLogado();
    this.servicosService.getCubosIC(this.usuarioLogado.codigofun).subscribe(sucesso => {
      this.listaCubos = sucesso.result;
      this.loading = false;
      this.trataLista();
    });
  }

  getUsuarioLogado(): void {
    this.usuarioLogado.codigofun = JSON.parse(
      atob(this.token.split('.')[1])
    ).codigofun;
    this.usuarioLogado.nome = JSON.parse(atob(this.token.split('.')[1])).nome;
    this.usuarioLogado.empresa = JSON.parse(
      atob(this.token.split('.')[1])
    ).empresa;
    this.usuarioLogado.grupoAcesso = JSON.parse(
      atob(this.token.split('.')[1])
    ).grupoAcesso;
  }

  trataLista(): void {
    let relatorio = 0;
    let indice = -1;
    for (const cubo of this.listaCubos) {
      if (cubo[7] !== relatorio) {
        const relat = {
          nome: cubo[0],
          sql: cubo[1],
          codigocubo: cubo[7],
          campos: []
        };
        this.listaCubosTratada.push(relat);
        relatorio = cubo[7];
        indice++;
      }
      const campo = {
        filtrodb: cubo[2],
        filtrofe: cubo[3],
        tipo: cubo[4],
        obrigatorio: cubo[5],
        ordem: cubo[8]
      };
      this.listaCubosTratada[indice].campos.push(campo);
    }

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.listaCubosTratada.length; i++) {
      this.listaCubosTratada[i].campos = this.listaCubosTratada[i].campos.sort((a, b) => {
        if (a.ordem > b.ordem) {
          return 1;
        }
        if (a.ordem < b.ordem) {
          return -1;
        }
        return 0;
      });
    }
    this.listaCubosTratada = this.listaCubosTratada.sort((a, b) => {
      if (a.nome > b.nome) {
        return 1;
      }
      if (a.nome < b.nome) {
        return -1;
      }
      return 0;
    });
  }

  pegaFiltros(codigofiltro: string): void {
    this.codigocubo = codigofiltro;
    this.filtros = [];
    this.relatorio = this.listaCubosTratada.filter(dados => dados.codigocubo.toString() === codigofiltro.toString());
    this.CSVoptions.title = this.relatorio[0].nome;
    const date = new Date();
    const dateStr = moment(date).format('DD_MM_YYYY_HH_mm');
    const re = new RegExp(' ', 'g');
    this.filename = this.relatorio[0].nome.replace(re, '_') + '_' + dateStr;
    this.listaFiltros = this.relatorio[0].campos;
    for (const filtro of this.listaFiltros) {
      const dado: Filtros = {
        alias: filtro.filtrodb,
        value: `'vazio'`
      };
      this.filtros.push(dado);
    }
  }

  registraDado(campo: string, valor: string, tipo: string): void {
    const upValor = valor.toUpperCase();
    if (upValor.length > 0) {
      let valorSql = '';
      if (tipo === 'STRING') {
        valorSql = `'${upValor}'`;
      }
      if (tipo === 'DATE') {
        valorSql = `to_date('${upValor}','dd/mm/yyyy')`;
      }
      if (tipo === 'LIST') {

        const valores: string[] = upValor.split(',');
        for (const [i, filtro] of valores.entries()) {
          i === 0 ? valorSql += `'${filtro.trim()}'` : valorSql += `,'${filtro.trim()}'`;
        }
        console.log(valorSql);
      }
      const index = this.filtros.map(dado => dado.alias).indexOf(campo);
      this.filtros[index].value = valorSql;
    }
  }

  geraRelatorio(): void {

    let sql: string = this.relatorio[0].sql;

    for (const filtro of this.filtros) {
      const re = new RegExp(filtro.alias, 'g');
      sql = sql.replace(re, filtro.value);
    }

    const pv = new RegExp(';', 'g');
    sql = sql.replace(pv, ',');

    this.consultaBanco(sql);

  }

  consultaBanco(comando: string): void {
    this.loadingData = true;
    this.servicosService.consultaBanco(comando).subscribe(sucesso => {
      this.dados = sucesso.result;
      this.servicosService.getLabelCubosIc(this.codigocubo).subscribe(label => {
        this.selecionados = label.result;
        this.CSVdataGenerator();
      });
    });
  }

  CSVdataGenerator(): void {
    this.csvData = [];
    this.CSVoptions.headers = this.selecionados;
    const clone = this.dados;

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < clone.length; i++){
      for (let j = 0; j < clone[i].length; j++){
        if (clone[i][j] === '' || clone[i][j] === null ||
            // tslint:disable-next-line: triple-equals
            clone[i][j].toString().length <= 0 || clone[i][j] == 0) {
          clone[i][j] = '-';
        }
      }
      this.csvData.push(clone[i]);
    }
    this.loadingData = false;
    this.done = true;
  }

  zerar(): void {
    this.loadingData = false;
    this.done = false;
  }


}
