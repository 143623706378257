import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'pt-detalhes-home',
  templateUrl: './detalhesHome.component.html',
  styleUrls: ['./detalhesHome.component.css'],
})
export class DetalhesHomeComponent implements OnInit {

  displayedColumns: string[];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private dialogRef: MatDialogRef<DetalhesHomeComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      thisData: any;
      thisNames: string[];
    }
  ) {
    this.dataSource = new MatTableDataSource(this.data.thisData);
    this.displayedColumns = this.data.thisNames;
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'Tipo': return item[0];
        case 'Status': return item[0];
        case 'Placa': return item[0];
        case 'Motivo': return item[0];
        case 'Gerencial': return item[0];
        case 'Estado': return item[0];
        case 'Gravidade': return item[0];
        case 'Marca': return item[0];
        default: return item[1];
      }
    };
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  verificaTipo(dado: any): any{
    return typeof(dado);
  }

  eNumero(dado: any): boolean{
    return typeof(dado) === 'number' ? true : false;
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
