import { LoginService } from 'src/app/login/login.service';
import { AESEncryptDecryptService } from './../../AESEncryptDecrypt.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComponentModel } from './component.model';
import { TabelaComponent } from './tabela/tabela.component';
import { GraficoComponent } from './grafico/grafico.component';
import { TituloComponent } from './titulo/titulo.component';
import { Component, OnInit, ComponentFactoryResolver, NgModule, Compiler, Injector, NgModuleRef, ViewContainerRef, ViewChild } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { GeradorService } from './gerador.service';
import { Titulo } from './titulo/titulo.model';
import { Subscription } from 'rxjs';
import { TEMPLATE_COMPONENTS } from './template-components.model';


@Component({
  selector: 'pt-gerador-relatorio-gerencial',
  templateUrl: './gerador-relatorio-gerencial.component.html',
  styleUrls: ['./gerador-relatorio-gerencial.component.css']
})
export class GeradorRelatorioGerencialComponent implements OnInit {

  relatoriosSub = new Subscription();
  relatorios: any[] = [];
  token: string;
  codigofun: string;
  tituloComponent: TituloComponent;
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;

  constructor(private bottomSheet: MatBottomSheet,
              private geradorService: GeradorService,
              private snackBar: MatSnackBar,
              private loginService: LoginService,
              private compiler: Compiler,
              private injector: Injector,
              private module: NgModuleRef<any>,
              private resolver: ComponentFactoryResolver
  ) { }


  ngOnInit(): void {
    this.token = this.loginService.getToken();
    this.codigofun = JSON.parse(atob(this.token.split('.')[1])).codigofun;
    this.geradorService.getRelatorios(Number(this.codigofun));
    this.relatoriosSub = this.geradorService.getRelatoriosListener().subscribe(resultado => {
      this.geradorDeInputs(resultado);
      //this.createDynamicComponent(this.relatorios[0].componente);
    });
  }

  createDynamicComponent(typeName: string): any {
    const htmlString = `<div>${ typeName }</div>`;
    const tmpCmp = Component({ template: htmlString })(class {});
    const tmpModule = NgModule({declarations: [tmpCmp] })(class {});

    this.compiler.compileModuleAndAllComponentsAsync(tmpModule).then(factories => {
      const f = factories.componentFactories[0];
      const cmpRef = f.create(this.injector, [], null, this.module);
      this.container.insert(cmpRef.hostView);
    });
  }

  geradorDeInputs(dados: any[]): void {
    for (const dado of dados) {
      const componente = dado.COMPONENT;
      const inputs = {
        id: dado.ID,
        titulo: dado.TITULO,
        width: dado.WIDTH
      };
      const data = { inputs, componente };
      this.relatorios.push(data);
    }

  }

  showAlert(component: any): void {
    this.snackBar.open('Deseja excluir esse item?', 'Sim', {
      duration: 2000,
    });
    this.snackBar._openedSnackBarRef.onAction().subscribe(() => {
      this.geradorService.pullRelatorios(component, Number(this.codigofun));
    });
  }

  openBottomSheet(): void {
    this.bottomSheet.open(OpcoesComponent);
  }
}


@Component({
  selector: 'pt-opcoes',
  templateUrl: 'opcoes.html',
  styleUrls: ['./gerador-relatorio-gerencial.component.css']
})
export class OpcoesComponent {

  titulo = false;
  grafico = false;
  tabela = false;
  escolher = false;
  escolhida = '';
  nomeTitulo = '';
  opcoes = [];

  frotaQnt = [
    'Situação dos Veículos',
    'Qnt Veículos Veículos/ Proprietário Legal', 'Qnt Veículos/ Marca',
    'Qnt Veículos/ Ano Modelo', 'Qnt Veículos/ Grupo Gerencial',
    'Qnt Veículos/ Unidade', 'Qnt Veículos/ Departamento'
  ];

  frotaR$ = [
    'Top 10 placas'
  ];

  manutencaoQnt = [
    'Qnt OS/ Status', 'Qnt OS/ Grupo Gerencial', 'Qnt OS/ Motivo',
    'Qnt OS/ Estado', 'Percentual Preventiva', 'IDF'
  ];

  manutencaoR$ = [
    'Top 10 oficinas', 'Gasto/ Status', 'Top 10 Unidades', 'Top 10 Itens Corretivos', 'Top 10 Itens Preventivo',
  ];

  multasQnt = [
    'Tipos de Infração', 'Gravidade', 'Descrição Multas', 'Top 20 Infratores', 'Multas/ Estado'
  ];

  multasR$ = [
    'Gasto por tipo', 'Top 10 Veículos', 'Top 10 Multas'
  ];

  constructor(private bottomSheetRef: MatBottomSheetRef<OpcoesComponent>,
              private geradorService: GeradorService,
              private loginService: LoginService) { }


  addTitulo(): any {
    const token = this.loginService.getToken();
    const codigofun = JSON.parse(atob(token.split('.')[1])).codigofun;
    this.geradorService.pushRelatorios(codigofun, 'TituloComponent', this.nomeTitulo, null);
    this.bottomSheetRef.dismiss();
  }

  addGrafico(grafico: string, espacos: string): any {
    const token = this.loginService.getToken();
    const codigofun = JSON.parse(atob(token.split('.')[1])).codigofun;
    this.geradorService.pushRelatorios(codigofun, 'GraficoComponent', grafico, espacos);
    this.bottomSheetRef.dismiss();
  }

  addTabela(): any {
    // this.geradorService.pushRelatorios(TabelaComponent);
    this.bottomSheetRef.dismiss();
  }

  setOpcoes(tipo: string): void {
    switch (tipo) {
      case 'frota':
        this.opcoes = this.frotaQnt;
        this.escolhida = 'Frota (Quantidade)';
        break;
      case 'manutencao':
        this.opcoes = this.manutencaoQnt;
        this.escolhida = 'Manutenção (Quantidade)';
        break;
      case 'multa':
        this.opcoes = this.multasQnt;
        this.escolhida = 'Multa (Quantidade)';
        break;
      case 'frotaR$':
        this.opcoes = this.frotaR$;
        this.escolhida = 'Frota (R$)';
        break;
      case 'manutencaoR$':
        this.opcoes = this.manutencaoR$;
        this.escolhida = 'Manutenção (R$)';
        break;
      case 'multaR$':
        this.opcoes = this.multasR$;
        this.escolhida = 'Multa (R$)';
        break;
    }
    this.escolher = true;
  }

}
