import { MultasService } from './../infracoes/infracoes.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ServicosService } from '../servicos/servicos.service';
import { MatDialog } from '@angular/material/dialog';
import { Tools } from '../../tools';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import { DetalhesHomeComponent } from '../home/detalhes/detalhesHome.component';
import { AllServicosComponent } from '../home/allServicos/allServicos.component';
import { StatusValor } from '../servicos/servicos.model';
import { Status } from '../atendimento/status.model';
import { ChartModel } from '../../charts.model';
import { AESEncryptDecryptService } from '../../AESEncryptDecrypt.service';


@Component({
  selector: 'pt-custo',
  templateUrl: './custo.component.html',
  styleUrls: ['./custo.component.css']
})
export class CustoComponent implements OnInit, OnDestroy {

  mySubscription: any;

  opcao = 'os';

  totalOS = 0;
  progress = 0;
  qntMulta = 0;
  valorMulta = 0;
  qntNIC = 0;
  valorNIC = 0;
  empresaTotal = 0;
  empresaAtual = 0;
  loadingTCO = false;

  full = false;
  ok = false;
  loadingFiltro = false;
  mobile: boolean;

  dataInicial: Date;
  dataFinal: Date;

  statusValor: StatusValor[];
  preventiva: StatusValor;
  corretiva: StatusValor;
  sinistro: StatusValor;
  outros: StatusValor;

  notFound = 'Sem dados para exibir';

  // top10placa
  placas: Status[] = [];
  placasChart: ChartModel = { data: null, type: null, options: null };
  // top10unidade
  unidades: Status[] = [];
  unidadesChart: ChartModel = { data: null, type: null, options: null };
  // top10placaMulta
  placasMulta: Status[] = [];
  placasMultaChart: ChartModel = { data: null, type: null, options: null };
  // top10motivos
  motivos: Status[] = [];
  motivosChart: ChartModel = { data: null, type: null, options: null };
  // top10corretivas
  corretivas: Status[] = [];
  corretivasChart: ChartModel = { data: null, type: null, options: null };
  // top10preventivas
  preventivas: Status[] = [];
  preventivasChart: ChartModel = { data: null, type: null, options: null };
  // economia
  economia: Status[] = [];
  economiaChart: ChartModel = { data: null, type: null, options: null };
  economiaChartTable: ChartModel = { data: null, type: null, options: null };
  // TCO
  tco: any[] = [];
  tcoChart: ChartModel = { data: null, type: null, options: null };
  // TCO
  tcoGeral: any[] = [];
  tcoChartGeral: ChartModel = { data: null, type: null, options: null };


  myOpts = {
    separator: '.',
  };

  constructor(private servicosService: ServicosService,
              private multasService: MultasService,
              private dialog: MatDialog,
              private router: Router,
              private crypto: AESEncryptDecryptService,
              private tools: Tools) {
              localStorage.setItem('component', 'custo');
              this.router.routeReuseStrategy.shouldReuseRoute = () => {
                return false;
              };
              this.mySubscription = this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                  this.router.navigated = false;
                }
              });
  }

  ngOnDestroy(): void {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    if (window.screen.width <= 768) {
      //  768px portrait
      this.mobile = true;
    } else {
      this.mobile = false;
    }
    this.dataInicial = this.tools.toDate(localStorage.getItem('dataInicial'));
    this.dataFinal = this.tools.toDate(localStorage.getItem('dataFinal'));
    this.geraDados();
  }

  setInicio(event: MatDatepickerInputEvent<Date>): void {
    this.loadingFiltro = false;
    this.dataInicial.setDate(event.value.getDate());
    localStorage.setItem('dataInicial', moment(this.dataInicial).format('DD/MM/YYYY'));
    this.geraDados();
  }

  setFim(event: MatDatepickerInputEvent<Date>): void {
    this.loadingFiltro = false;
    this.dataFinal.setDate(event.value.getDate());
    localStorage.setItem('dataFinal', moment(this.dataFinal).format('DD/MM/YYYY'));
    this.geraDados();
  }

  trocaFiltro(filtro: string): void {
    this.loadingFiltro = false;
    this.opcao = filtro;
    localStorage.setItem('opcao', this.opcao);
    this.geraDados();
  }

  geraGraficos(): void {
    this.generateTop10PlacasChart();
    this.generateTop10UnidadesChart();
    this.generateTop10PlacaMultaChart();
    this.generateTop10MotivoMultaChart();
    this.generateTop10CorretivasChart();
    this.generateTop10PreventivasChart();
    this.generateEconomiaChart();
  }


  geraDados(): void {
    this.loadingTCO = true;
    this.loadingFiltro = true;
    this.progress = 0;
    // status e valores
    this.servicosService.generateStatusValores().subscribe(statusValor => {
      this.progress = 14;
      this.statusValor = statusValor.result;
      this.separarStatus(statusValor.result);
      //  top 10 placas
      this.servicosService.generateTop10Placa().subscribe(placas => {
        this.progress = 29;
        this.placas = placas.result;
        //  top 10 unidades
        this.servicosService.generateTop10Unidade().subscribe(unidades => {
          this.progress = 42;
          this.unidades = unidades.result;
          // gasto multa e nic
          this.multasService.getMultasValores().subscribe(valoresMultas => {
            this.progress = 57;
            this.geraTipo(valoresMultas.result);
            // top 10 placas multa
            this.multasService.getTop10PlacasValores().subscribe(placasMulta => {
              this.progress = 71;
              this.placasMulta = placasMulta.result;
              // top 10 tipos multa
              this.multasService.getTop10ClassificacaoValores().subscribe(motivos => {
                this.progress = 85;
                this.motivos = motivos.result;
                // top 10 corretivas
                this.servicosService.generateTop10Corretivas().subscribe(corretivas => {
                  this.progress = 90;
                  this.corretivas = corretivas.result;
                  // top 10 preventivas
                  this.servicosService.generateTop10Preventivas().subscribe(preventivas => {
                    this.progress = 99;
                    this.preventivas = preventivas.result;
                    // economia
                    this.servicosService.generateEconomia().subscribe(economia => {
                      this.economia = economia.result;
                      this.ok = true;
                      this.loadingFiltro = false;
                      this.geraGraficos();
                      this.geraTCO().then(() => {
                        this.generateTCOChart();
                        this.generateTCOGeral();
                      });
                    }, erro => {
                      console.log('Erro ao obter economia');
                      console.log(erro);
                    });
                  }, erro => {
                    console.log('Erro ao obter top 10 preventivas');
                    console.log(erro);
                  });
                }, erro => {
                  console.log('Erro ao obter top 10 corretivas');
                  console.log(erro);
                });
              }, erro => {
                console.log('Erro ao obter top 10 multas tipo');
                console.log(erro);
              });
            }, erro => {
              console.log('Erro ao obter top 10 multas placa');
              console.log(erro);
            });
          }, erro => {
            console.log('Erro ao obter valores multas');
            console.log(erro);
          });
        }, erro => {
          console.log('Erro ao obter top 10 unidades');
          console.log(erro);
        });
      }, erro => {
        console.log('Erro ao obter top 10 placas');
        console.log(erro);
      });
    }, erro => {
      console.log('Erro ao obter status');
      console.log(erro);
    });
  }

  separarStatus(status): void {
    for (const stat of status) {
      switch (stat[0]) {
        case 'PREVENTIVA':
          this.preventiva = stat;
          break;
        case 'CORRETIVA':
          this.corretiva = stat;
          break;
        case 'SINISTRO':
          this.sinistro = stat;
          break;
        case 'OUTROS':
          this.outros = stat;
          break;
      }
    }
  }

  geraTipo(dados): void {
    if (dados.length > 0) {
      const nic = dados.filter(dado => dado[0] === 'NIC');
      this.qntNIC = nic[0][2];
      this.valorNIC = nic[0][1];
      const multa = dados.filter(dado => dado[0] !== 'NIC');
      let somaMulta = 0;
      let somaQnt = 0;
      for (const m of multa) {
        somaMulta += m[1];
        somaQnt += m[2];
      }
      this.qntMulta = somaQnt;
      this.valorMulta = somaMulta;
    }
  }

  async geraTCO(): Promise<any> {
    const dataInicial = localStorage.getItem('dataInicial');
    const dataFinal = localStorage.getItem('dataFinal');
    const empresa = this.crypto.decrypt(localStorage.getItem('empresa')).split(',');

    this.empresaAtual = 0;
    this.empresaTotal = empresa.length;

    for (const [index, emp] of empresa.entries()) {

      await this.servicosService.generateTCO(dataInicial, dataFinal, emp.replace(/\s/g, '')).subscribe(sucesso => {
        this.empresaAtual++;
        if (sucesso.result.length > 0) {
          for (const suc of sucesso.result) {
            this.tco.push(suc);
          }
          if (this.empresaAtual === this.empresaTotal) {
            this.loadingTCO = false;
            this.generateTCOGeral();
          }
        }
      });
    }
  }

  generateTCOChart(): void {
    this.tcoChart.type = 'Table';
    this.tcoChart.data = this.tco;

    this.tcoChart.names = [
      'VEICULO', 'CENTRO CUSTO', 'GRUPO GERENCIAL', 'MATRICULA', 'USUARIO', 'GESTAO',
      'CORRETIVA', 'PREVENTIVA', 'SINISTRO', 'TOTAL MANUTENÇÃO', 'IPVA', 'IMPOSTO',
      'HONORARIOS', 'TOTAL DESPACHANTE', 'MULTAS', 'COMBUSTIVEL', 'SEM PARAR',
      'ASSISTÊNCIA', 'VISTORIA', 'LOCACAO', 'TOTAL'
    ];
    this.tcoChart.options = {
      alternatingRowStyle: true,
      chartArea: { width: '100%' },
      backgroundColor: 'red',
      position: 'center',
      cssClassNames: {
        headerRow: 'table-header',
        tableRow: 'table-row',
        oddTableRow: 'table-row'
      },
      frozenColumns: 1,
      page: 'enable',
      pageSize: 5,
      pagingSymbols: {
        prev: '   <   ',
        next: '   >   ',
      },
      pagingButtonsConfiguration: 'auto',
    };
  }

  generateTCOGeral(): void {
    let combustivel = 0;
    let manutencao = 0;
    let documentacao = 0;
    let gestao = 0;
    let locacao = 0;
    let outros = 0;
    let total = 0;

    for (const placa of this.tco) {
      combustivel += placa[15];
      manutencao += placa[9];
      documentacao += placa[13];
      gestao += placa[5];
      locacao += placa[19];
      total += placa[20];
      outros += placa[20] - placa[19] - placa[5] - placa[13] -
        placa[9] - placa[15];
    }
    this.tcoGeral.push([
      combustivel, manutencao, documentacao, gestao, locacao, outros, total
    ]);
    this.tcoChartGeral.type = 'ColumnChart';
    this.tcoChartGeral.data = this.tcoGeral;
    this.tcoChartGeral.names = [
      'COMBUSTÍVEL', 'MANUTENÇÃO', 'DOCUMENTAÇÃO', 'GESTÃO',
      'LOCAÇÃO', 'OUTROS', 'TOTAL'
    ];
    const color = [this.tools.randomColorGenerator(), this.tools.randomColorGenerator(),
    this.tools.randomColorGenerator(), this.tools.randomColorGenerator(),
    this.tools.randomColorGenerator(), this.tools.randomColorGenerator(),
    this.tools.randomColorGenerator()];
    this.tcoChartGeral.options = {
      animation: {
        duration: 1000,
        easing: 'out',
        startup: true,
      },
      chartArea: { height: '95%', width: '50%' },
      hAxis: {
        textStyle: {
          fontSize: 8,
        },
      },
      legend: { position: 'right' },
      backgroundColor: 'transparent',
      position: 'center',
      colors: color
    };
  }

  generateEconomiaChart(): void {
    const labels = [''];
    let data = [];
    data.push(['Valor Solicitado', 'Valor Aprovado', 'Economia Total', 'Percentual de Economia']);
    for (const dado of this.economia) {
      labels.push(dado[0]);
      const novoDado = [Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(dado[1]),
      Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(dado[2]),
      Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(dado[3]),
      Intl.NumberFormat('pt-BR', { style: 'percent', }).format(dado[4])];
      data.push(novoDado);
    }
    data = this.transpor(data);
    this.economiaChartTable.type = 'Table';
    this.economiaChartTable.data = data;
    this.economiaChartTable.names = labels;
    this.economiaChartTable.options = {
      alternatingRowStyle: true,
      chartArea: { width: '100%' },
      backgroundColor: 'transparent',
      position: 'center',
      cssClassNames: {
        headerRow: 'table-header',
        tableRow: 'table-row',
        oddTableRow: 'table-row'
      }
    };
  }

  // Gera o Gráfico de motivos
  generateTop10PlacasChart(): void {
    this.placasChart.type = 'BarChart';
    this.placasChart.data = this.placas;
    this.placasChart.options = {
      animation: {
        duration: 1000,
        easing: 'out',
        startup: true,
      },
      chartArea: { height: '90%' },
      hAxis: {
        textStyle: {
          fontSize: 8,
        },
      },
      legend: { position: 'none' },
      backgroundColor: 'transparent',
      position: 'center',
      colors: [this.tools.randomColorGenerator()]
    };
  }

  // Gera o Gráfico de top 10 manutenção
  generateTop10UnidadesChart(): void {
    this.unidadesChart.type = 'BarChart';
    this.unidadesChart.data = this.unidades;
    this.unidadesChart.options = {
      animation: {
        duration: 1000,
        easing: 'out',
        startup: true,
      },
      chartArea: { height: '90%' },
      hAxis: {
        textStyle: {
          fontSize: 8,
        },
      },
      legend: { position: 'none' },
      backgroundColor: 'transparent',
      position: 'center',
      colors: [this.tools.randomColorGenerator()]
    };
  }

  // Gera o Gráfico de top 10 placa multa
  generateTop10PlacaMultaChart(): void {
    this.placasMultaChart.type = 'BarChart';
    this.placasMultaChart.data = this.placasMulta;
    this.placasMultaChart.options = {
      animation: {
        duration: 1000,
        easing: 'out',
        startup: true,
      },
      chartArea: { height: '90%' },
      hAxis: {
        textStyle: {
          fontSize: 8,
        },
      },
      legend: { position: 'none' },
      backgroundColor: 'transparent',
      position: 'center',
      colors: [this.tools.randomColorGenerator()]
    };
  }

  // Gera o Gráfico de top 10 motivo multa
  generateTop10MotivoMultaChart(): void {
    this.motivosChart.type = 'BarChart';
    this.motivosChart.data = this.motivos;
    this.motivosChart.options = {
      animation: {
        duration: 1000,
        easing: 'out',
        startup: true,
      },
      chartArea: { height: '90%', width: '30%' },
      vAxis: {
        textStyle: {
          fontSize: 10,
        },
      },
      legend: { position: 'none' },
      backgroundColor: 'transparent',
      position: 'right',
      colors: [this.tools.randomColorGenerator()]
    };
  }

  // Gera o Gráfico de top 10 corretivas
  generateTop10CorretivasChart(): void {
    this.corretivasChart.type = 'BarChart';
    this.corretivasChart.data = this.corretivas;
    this.corretivasChart.options = {
      animation: {
        duration: 1000,
        easing: 'out',
        startup: true,
      },
      chartArea: { height: '90%', width: '30%' },
      vAxis: {
        textStyle: {
          fontSize: 10,
        },
      },
      legend: { position: 'none' },
      backgroundColor: 'transparent',
      position: 'right',
      colors: [this.tools.randomColorGenerator()]
    };
  }

  // Gera o Gráfico de top 10 corretivas
  generateTop10PreventivasChart(): void {
    this.preventivasChart.type = 'BarChart';
    this.preventivasChart.data = this.preventivas;
    this.preventivasChart.options = {
      animation: {
        duration: 1000,
        easing: 'out',
        startup: true,
      },
      chartArea: { height: '90%', width: '30%' },
      vAxis: {
        textStyle: {
          fontSize: 10,
        },
      },
      legend: { position: 'none' },
      backgroundColor: 'transparent',
      position: 'right',
      colors: [this.tools.randomColorGenerator()]
    };
  }

  abrirDetalhes(dado: string): void {
    let data: any;
    let names: string[] = [];

    switch (dado) {
      case 'placas':
        data = this.placas;
        names = ['Placa', 'Gasto'];
        break;
      case 'unidades':
        data = this.unidades;
        names = ['Unidade', 'Gasto'];
        break;
      case 'corretivas':
        data = this.corretivas;
        names = ['Item', 'Gasto'];
        break;
      case 'preventivas':
        data = this.preventivas;
        names = ['Item', 'Gasto'];
        break;
      case 'placasMultas':
        data = this.placasMulta;
        names = ['Placa', 'Gasto'];
        break;
      case 'motivos':
        data = this.motivos;
        names = ['Multa', 'Gasto'];
        break;
    }

    this.dialog.open(DetalhesHomeComponent, {
      width: 'wrap',
      height: 'wrap',
      panelClass: 'dialog-transparent',
      data: {
        thisData: data,
        thisNames: names,
      },
    });
  }

  isFullScreen(): boolean {
    return (
      (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.fullscreenElement && document.fullscreenElement !== null)
    );
  }

  enterFS(id): void {
    const page = document.getElementById(id);
    if (page.requestFullscreen) {
      page.requestFullscreen();
    } else if (page.requestFullscreen) {
      page.requestFullscreen();
    }
  }

  exitFS(): Promise<void> {
    if (document.exitFullscreen) {
      return document.exitFullscreen();
    } else if (document.exitFullscreen) {
      return document.exitFullscreen();
    }
  }

  toggleFS(id): void {
    if (!this.isFullScreen()) {
      this.full = true;
      this.enterFS(id);
    } else {
      this.full = false;
      this.exitFS();
    }
  }

  transpor(a): any[] {
    return Object.keys(a[0]).map(c => {
      return a.map(r => {
        return r[c];
      });
    });
  }

}
