import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class FrotaSQL {

  marcaSQL(empresa: string): string {
    return `select marca ||' (' || total || ')'  as marca, total as total from
            (select f.marca as marca, count(*) as total from ga_frota_ativa f
            where f.situacao = 'A'
            and f.proprietario in ( ${empresa} )
            group by f.marca
            order by 2 desc)`;
  }

  anoSQL(empresa: string): string {
    return `select ano_modelo ||' (' || total || ')'  as ano_modelo, total as total from
            (select f.ano_modelo as ano_modelo, count(*) as total from ga_frota_ativa f
            where f.situacao = 'A'
            and f.proprietario in ( ${empresa} )
            group by f.ano_modelo)
            order by 1 desc`;
  }

  proprietarioSQL(empresa: string): string {
    return `select propri_legal ||' (' || total || ')'  as propri_legal, total as total from
            (select f.propri_legal as propri_legal, count(*) as total from ga_frota_ativa f
            where f.situacao = 'A'
            and f.proprietario in ( ${empresa} )
            group by f.propri_legal)
            order by 2 desc`;
  }

  unidadeSQL(empresa: string): string {
    return `select unidade ||' (' || total || ')'  as unidade, total as total from
            (select f.unidade as unidade, count(*) as total from ga_frota_ativa f
            where f.situacao = 'A'
            and f.proprietario in ( ${empresa} )
            group by f.unidade)
            order by 2 desc`;
  }

  departamentoSQL(empresa: string): string {
    return `select departamento ||' (' || total || ')'  as departamento, total as total from
            (select f.setor as departamento, count(*) as total from ga_frota_ativa f
            where f.situacao = 'A'
            and f.proprietario in ( ${empresa} )
            group by f.setor)
            order by 2 desc`;
  }

  grupoSQL(empresa: string): string {
    return `select gerencial ||' (' || total || ')'  as gerencial, total as total from
            (select f.gerencial as gerencial, count(*) as total from ga_frota_ativa f
            where f.situacao = 'A'
            and f.proprietario in ( ${empresa} )
            group by f.gerencial)
            order by 2 desc`;
  }

  situacaoSQL(empresa: string): string {
    return `select f.sitcar as sitcar, count(*) as total from ga_frota_ativa f
       where f.proprietario in ( ${empresa} )
       group by f.sitcar
       order by 2 desc`;
  }

  allSQL(empresa: string): string {
    return `select *
            from GA_FROTA_ATIVA
            where proprietario in ( ${empresa} )`;
  }

  labelsSQL(): string{
    const grupo = localStorage.getItem('gerencial_db').toUpperCase();
    return `SELECT column_name FROM all_tab_cols
            WHERE table_name = 'GA_FROTA_ATIVA' AND OWNER = '${grupo === 'ALELO' ? environment.banco_alelo : environment.banco_tfleet }'
            ORDER BY COLUMN_ID`;
  }

  labelsSQLR(): string{
    const grupo = localStorage.getItem('gerencial_db').toUpperCase();
    return `SELECT column_name FROM all_tab_cols
            WHERE table_name = 'GA_FROTA_ATIVA' AND OWNER = '${grupo === 'ALELO' ? environment.banco_alelo : environment.banco_tfleet }'
            ORDER BY column_name`;
  }

}
