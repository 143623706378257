import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { FrotaSQL} from './frota.sql';

import {
  Marca,
  AnoModelo,
  PropriLegal,
  Unidade,
  Departamento,
  GrupoGerencial,
  Situacao,
} from './frota.model';
import { AESEncryptDecryptService } from '../../AESEncryptDecrypt.service';
import { Status } from '../atendimento/status.model';
import { Observable } from 'rxjs';

const BACKEND_URL = environment.apiUrl + '/consulta';

@Injectable({ providedIn: 'root' })
export class FrotaService {


  database = localStorage.getItem('gerencial_db');

  constructor(private http: HttpClient,
              private sqlList: FrotaSQL,
              private crypto: AESEncryptDecryptService) {}

  // marca
  generateMarca(): Observable<any> {
    const sql = this.sqlList.marcaSQL(this.crypto.decrypt(localStorage.getItem('empresa')));
    return this.http.post<{ result: Marca[] }>(BACKEND_URL + '/consulta', {
      sql, db: this.database
    });
  }

  // ano
  generateAno(): Observable<any> {
    const sql = this.sqlList.anoSQL(this.crypto.decrypt(localStorage.getItem('empresa')));
    return this.http.post<{ result: AnoModelo[] }>(BACKEND_URL + '/consulta', {
      sql, db: this.database
    });
  }

  // proprietario legal
  generateProprietario(): Observable<any> {
    const sql = this.sqlList.proprietarioSQL(this.crypto.decrypt(localStorage.getItem('empresa')));
    return this.http.post<{ result: PropriLegal[] }>(
      BACKEND_URL + '/consulta', {
        sql, db: this.database
      }
    );
  }

  // unidade
  generateUnidade(): Observable<any> {
    const sql = this.sqlList.unidadeSQL(this.crypto.decrypt(localStorage.getItem('empresa')));
    return this.http.post<{ result: Unidade[] }>(BACKEND_URL + '/consulta', {
      sql, db: this.database
    });
  }

  // departamento
  generatDepartamento(): Observable<any> {
    const sql = this.sqlList.departamentoSQL(this.crypto.decrypt(localStorage.getItem('empresa')));
    return this.http.post<{ result: Departamento[] }>(
      BACKEND_URL + '/consulta', {
        sql, db: this.database
      });
  }

  // grupo
  generateGrupo(): Observable<any> {
    const sql = this.sqlList.grupoSQL(this.crypto.decrypt(localStorage.getItem('empresa')));
    return this.http.post<{ result: GrupoGerencial[] }>(
      BACKEND_URL + '/consulta', {
        sql, db: this.database
      });
  }
  // situacao
  generateSituacao(): Observable<any> {
    const sql = this.sqlList.situacaoSQL(this.crypto.decrypt(localStorage.getItem('empresa')));
    return this.http.post<{ result: Situacao[] }>(BACKEND_URL + '/consulta', {
      sql, db: this.database
    });
  }

  generatAll(): Observable<any> {
    const sql = this.sqlList.allSQL(this.crypto.decrypt(localStorage.getItem('empresa')));
    return this.http.post<{ result: Status[] }>(BACKEND_URL + '/consulta', {sql, db: this.database});
  }

  getLabels(): Observable<any> {
    const sql = this.sqlList.labelsSQL();
    return this.http.post<{ result: string[] }>(BACKEND_URL + '/consulta', {sql, db: this.database});
  }

  getLabelsR(): Observable<any> {
    const sql = this.sqlList.labelsSQLR();
    return this.http.post<{ result: string[] }>(BACKEND_URL + '/consulta', {sql, db: this.database});
  }
}
