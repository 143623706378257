import { Component, OnInit, OnDestroy } from '@angular/core';
import { FrotaService } from './frota.service';
import { Login } from 'src/app/login/login.model';
import {
  Marca,
  AnoModelo,
  PropriLegal,
  Unidade,
  Departamento,
  GrupoGerencial,
  Situacao,
} from './frota.model';
import { LoginService } from 'src/app/login/login.service';
import { ChartModel } from '../../charts.model';
import { NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DetalhesHomeComponent } from '../home/detalhes/detalhesHome.component';
import { AllServicosComponent } from '../home/allServicos/allServicos.component';
import { Tools } from '../../tools';

@Component({
  selector: 'pt-frota',
  templateUrl: './frota.component.html',
  styleUrls: ['./frota.component.css'],
})
export class FrotaComponent implements OnInit, OnDestroy {
  ok = false;
  token: string;
  frotaAtiva = 0;
  progress = 0;

  // gráfico marcas
  marcas: Marca[] = [];
  marcaChart: ChartModel = { data: null, type: null, options: null };
  // gráfico Ano
  anos: AnoModelo[] = [];
  anoChart: ChartModel = { data: null, type: null, options: null };
  // gráfico proprietario
  proprietarios: PropriLegal[] = [];
  proprietarioChart: ChartModel = { data: null, type: null, options: null };
  // gráfico unidade
  unidades: Unidade[] = [];
  unidadeChart: ChartModel = { data: null, type: null, options: null };
  // gráfico departamento
  departamentos: Departamento[] = [];
  departamentoChart: ChartModel = { data: null, type: null, options: null };
  // gráfico grupo
  gerenciais: GrupoGerencial[] = [];
  grupoChart: ChartModel = { data: null, type: null, options: null };
  // gráfico situacao
  situacoes: Situacao[] = [];
  situcacaoChart: ChartModel = { data: null, type: null, options: null };
  // gráfico situacao table
  situcacaoChartTable: ChartModel = { data: null, type: null, options: null };
  // gráfico total Table
  totalChart: ChartModel = { data: null, type: null, options: null };

  usuarioLogado: Login = {
    codigofun: null,
    nome: null,
    empresa: null,
    grupoAcesso: null,
  };

  myOpts = {
    separator: '.',
  };

  mySubscription: any;

  constructor(private frotaService: FrotaService,
              private loginService: LoginService,
              private router: Router,
              private tools: Tools,
              private dialog: MatDialog) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    localStorage.setItem('component', 'frota');
    this.getToken();
    this.getUsuarioLogado();
    this.generateData();
  }

  // Pega o token que contém os dados do usuário
  getToken(): void {
    this.token = this.loginService.getToken();
  }

  // Preenche os dados do usuário logado pelo token
  getUsuarioLogado(): void {
    this.usuarioLogado.codigofun = JSON.parse(
      atob(this.token.split('.')[1])
    ).codigofun;
    this.usuarioLogado.nome = JSON.parse(atob(this.token.split('.')[1])).nome;
    this.usuarioLogado.empresa = JSON.parse(
      atob(this.token.split('.')[1])
    ).empresa;
    this.usuarioLogado.grupoAcesso = JSON.parse(
      atob(this.token.split('.')[1])
    ).grupoAcesso;
  }

  generateData(): void {
    // marca
    this.frotaService.generateMarca().subscribe(marcas => {
      this.marcas = marcas.result;
      this.progress = 17;
      // ano
      this.frotaService.generateAno().subscribe(anos => {
        this.anos = anos.result;
        this.progress = 33;
        // proprietario
        this.frotaService.generateProprietario().subscribe(proprietarios => {
          this.proprietarios = proprietarios.result;
          this.progress = 50;
          // unidade
          this.frotaService.generateUnidade().subscribe(unidades => {
            this.unidades = unidades.result;
            this.progress = 67;
            // departamento
            this.frotaService.generatDepartamento().subscribe(departamentos => {
              this.departamentos = departamentos.result;
              this.progress = 83;
              // gerencial
              this.frotaService.generateGrupo().subscribe(gerenciais => {
                this.gerenciais = gerenciais.result;
                this.progress = 98;
                // situcacao
                this.frotaService.generateSituacao().subscribe(situacoes => {
                  this.situacoes = situacoes.result;
                  this.progress = 100;
                  this.generateCharts();
                  this.ok = true;
                },
                  (erro) => {
                    console.log(
                      'Erro ao obter dados de situacao: '
                    );
                    console.log(erro);
                  }
                );
              },
                (erro) => {
                  console.log('Erro ao obter dados de grupo:');
                  console.log(erro);
                }
              );
            },
              (erro) => {
                console.log('Erro ao obter dados de departamento:');
                console.log(erro);
              }
            );
          },
            (erro) => {
              console.log('Erro ao obter dados de unidade:');
              console.log(erro);
            }
          );
        },
          (erro) => {
            console.log('Erro ao obter dados de proprietarios:');
            console.log(erro);
          }
        );
      },
        (erro) => {
          console.log('Erro ao obter dados de ano modelo:');
          console.log(erro);
        }
      );
    },
      (erro) => {
        console.log('Erro ao obter dados de marca:');
        console.log(erro);
      }
    );
  }

  generateCharts(): void {
    this.generateMarcaChart();
    this.generateAnoChart();
    this.generateProprietarioChart();
    this.generateUnidadeChart();
    this.generateDepartamentoChart();
    this.generateGrupoChart();
    this.generateSituacaoChart();
  }

  // Gera o Gráfico de Marca
  generateMarcaChart(): void {

    for (const marca of this.marcas){
      this.frotaAtiva += marca[1];
    }

    this.marcaChart.type = 'BarChart';
    this.marcaChart.data = this.marcas;
    this.marcaChart.options = {
      animation: {
        duration: 1000,
        easing: 'out',
        startup: true,
      },
      annotations: {
        alwaysOutside: true,
      },
      chartArea: { height: '90%' },
      vAxis: {
        textStyle: {
          fontSize: 10,
        },
      },
      legend: { position: 'none' },
      backgroundColor: 'transparent',
      position: 'center',
      colors: [this.tools.randomColorGenerator()]
    };
  }

  // Gera o Gráfico de Ano Modelo
  generateAnoChart(): void {
    this.anoChart.type = 'ColumnChart';
    this.anoChart.data = this.anos;
    this.anoChart.options = {
      animation: {
        duration: 1000,
        easing: 'out',
        startup: true,
      },
      chartArea: { width: '90%' },
      vAxis: {
        textStyle: {
          fontSize: 10,
        },
      },
      legend: { position: 'none' },
      backgroundColor: 'transparent',
      position: 'center',
      colors: [this.tools.randomColorGenerator()]
    };
  }

  // Gera o Gráfico de proprietario
  generateProprietarioChart(): void {
    this.proprietarioChart.type = 'ColumnChart';
    this.proprietarioChart.data = this.proprietarios;
    this.proprietarioChart.options = {
      animation: {
        duration: 1000,
        easing: 'out',
        startup: true,
      },
      chartArea: { width: '90%' },
      vAxis: {
        textStyle: {
          fontSize: 10,
        },
      },
      legend: { position: 'none' },
      backgroundColor: 'transparent',
      position: 'center',
      colors: [this.tools.randomColorGenerator()]
    };
  }

  // Gera o Gráfico de unidade
  generateUnidadeChart(): void {
    this.unidadeChart.type = 'BarChart';
    this.unidadeChart.data = this.unidades;
    this.unidadeChart.options = {
      animation: {
        duration: 1000,
        easing: 'out',
        startup: true,
      },
      chartArea: { height: '90%', width: '30%' },
      vAxis: {
        textStyle: {
          fontSize: 10,
        },
      },
      legend: { position: 'none' },
      backgroundColor: 'transparent',
      position: 'center',
      colors: [this.tools.randomColorGenerator()]
    };
  }

  // Gera o Gráfico de departamento
  generateDepartamentoChart(): void {
    this.departamentoChart.type = 'BarChart';
    this.departamentoChart.data = this.departamentos;
    this.departamentoChart.options = {
      animation: {
        duration: 1000,
        easing: 'out',
        startup: true,
      },
      chartArea: { height: '90%', width: '30%' },
      vAxis: {
        textStyle: {
          fontSize: 10,
        },
      },
      legend: { position: 'none' },
      backgroundColor: 'transparent',
      position: 'center',
      colors: [this.tools.randomColorGenerator()]
    };
  }

  // Gera o Gráfico de Grupo Gerencial
  generateGrupoChart(): void {
    this.grupoChart.type = 'BarChart';
    this.grupoChart.data = this.gerenciais;
    this.grupoChart.options = {
      animation: {
        duration: 1000,
        easing: 'out',
        startup: true,
      },
      chartArea: { height: '90%', width: '30%' },
      vAxis: {
        textStyle: {
          fontSize: 10,
        },
      },
      legend: { position: 'none' },
      backgroundColor: 'transparent',
      position: 'center',
      colors: [this.tools.randomColorGenerator()]
    };
  }

  // Gera o Gráfico de Situacao
  generateSituacaoChart(): void {
    this.situcacaoChart.type = 'PieChart';
    this.situcacaoChart.data = this.situacoes;
    this.situcacaoChart.options = {
      pieHole: 0.4,
      chartArea: { height: '90%' },
      legend: { position: 'left', fontsize: '1rem' },
      backgroundColor: 'transparent',
      position: 'center',
    };

    let total = 0;
    const concatenado = [];

    for (const situacao of this.situacoes) {
      total += situacao[1];
      concatenado.push(situacao);
    }

    const newTotal = ['Total', total];
    concatenado.push(newTotal);
    this.situcacaoChartTable.type = 'Table';
    this.situcacaoChartTable.data = concatenado;
    this.situcacaoChartTable.names = ['Situação', 'Veiculos'];
    this.situcacaoChartTable.options = {
      alternatingRowStyle: true,
      chartArea: { width: '90%' },
      backgroundColor: 'transparent',
      position: 'center',
    };
  }

  isFullScreen(): boolean {
    return (
      (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.fullscreenElement && document.fullscreenElement !== null)
    );
  }

  enterFS(id: string): void {
    const page = document.getElementById(id);
    if (page.requestFullscreen) {
      page.requestFullscreen();
    } else if (page.requestFullscreen) {
      page.requestFullscreen();
    }
  }

  exitFS(): Promise<void> {
    if (document.exitFullscreen) {
      return document.exitFullscreen();
    } else if (document.exitFullscreen) {
      return document.exitFullscreen();
    }
  }

  toggleFS(id): void {
    if (!this.isFullScreen()) {
      this.enterFS(id);
    } else {
      this.exitFS();
    }
  }

  abrirDetalhes(dado: string): void {
    let data: any;
    let names: string[] = [];

    switch (dado) {
      case 'situacao':
        data = this.situacoes;
        names = ['Status', 'Quantidade'];
        break;
      case 'proprietario':
        data = this.proprietarios;
        names = ['Status', 'Quantidade'];
        break;
      case 'marca':
        data = this.marcas;
        names = ['Status', 'Quantidade'];
        break;
      case 'ano':
        data = this.anos;
        names = ['Status', 'Quantidade'];
        break;
      case 'grupo':
        data = this.gerenciais;
        names = ['Status', 'Quantidade'];
        break;
      case 'unidade':
        data = this.unidades;
        names = ['Status', 'Quantidade'];
        break;
      case 'departamento':
        data = this.departamentos;
        names = ['Status', 'Quantidade'];
        break;
    }

    this.dialog.open(DetalhesHomeComponent, {
      width: 'wrap',
      height: 'wrap',
      panelClass: 'dialog-transparent',
      data: {
        thisData: data,
        thisNames: names,
      },
    });
  }

  abrirAll(): void {
    this.dialog.open(AllServicosComponent, {
      width: '98vw',
      height: 'wrap',
      panelClass: 'dialog-transparent',
      data: {
        tipo: 'frota',
      },
    });
  }


}
