import { Component } from '@angular/core';
import { LoginService } from './login/login.service';

@Component({
  selector: 'pt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'painel-tfleet';
  constructor(private loginService: LoginService){}

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnInit(): void {
    this.loginService.autoAuthUser();
  }
}
