import { Component, Input } from '@angular/core';
import { Angular2CsvComponent } from 'angular2-csv';
@Component({
selector: 'pt-export-csv',
template: '<div (click)="onDownload()"><ng-content></ng-content></div>'
})
export class ExportCSVComponent extends Angular2CsvComponent {
    @Input() data;
    @Input() filename;
    @Input() options;
}
