import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Resumo } from './resumo.model';
import { Status } from './status.model';
import { AtendimentoSQL } from './atendimento.sql';

const BACKEND_URL = environment.apiUrl + '/atendimento';

@Injectable({ providedIn: 'root' })
export class AtendimentoService {

  database = localStorage.getItem('gerencial_db');

  constructor(private http: HttpClient, private sqlList: AtendimentoSQL) {}

  generateResumo(dias: number, empresa: string): any {
    const sql = this.sqlList.resumoSQL(dias, empresa);
    return this.http.post<{ result: Resumo }>(BACKEND_URL + '/resumo', {
      sql, db: this.database
    });
  }

  generateStatus(dias: number, empresa: string): any {
    const sql = this.sqlList.statusSQL(dias, empresa);
    return this.http.post<{ result: Status[] }>(BACKEND_URL + '/status', {
      sql, db: this.database
    });
  }
}
