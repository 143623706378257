import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import * as moment from 'moment';

import { MultasService } from './infracoes.service';
import { Tools } from '../../tools';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Status } from '../atendimento/status.model';
import { ChartModel } from '../../charts.model';
import { DetalhesHomeComponent } from '../home/detalhes/detalhesHome.component';
import { MatDialog } from '@angular/material/dialog';
import { AllServicosComponent } from '../home/allServicos/allServicos.component';

@Component({
  selector: 'pt-infracoes',
  templateUrl: './infracoes.component.html',
  styleUrls: ['./infracoes.component.css']
})
export class InfracoesComponent implements OnInit, OnDestroy {

  mySubscription: any;
  mobile = false;

  // tipos
  tiposChart: ChartModel = { data: null, type: null, options: null };
  tipos: Status[];
  // gravidade
  gravidadeChart: ChartModel = { data: null, type: null, options: null };
  gravidades: Status[];
  // descricao
  descricaoChart: ChartModel = { data: null, type: null, options: null };
  descricoes: Status[];
  // mensais
  mensalChart: ChartModel = { data: null, type: null, options: null };
  mensalChartTable: ChartModel = { data: null, type: null, options: null };
  mensais: Status[];
  // top10
  top10ChartTable: ChartModel = { data: null, type: null, options: null };
  top10: Status[];
  // gravissimas
  gravissimasChartTable: ChartModel = { data: null, type: null, options: null };
  gravissimas: Status[];
  // estado
  estadosChart: ChartModel = { data: null, type: null, options: null };
  estados: Status[];

  dataInicial: Date;
  dataFinal: Date;

  multa = 0;
  nic = 0;

  progress = 0;
  ok = false;
  loadingFiltro = false;
  full = false;

  myOpts = {
    separator: '.',
  };

  constructor(private tools: Tools,
              private router: Router,
              private multasService: MultasService,
              private dialog: MatDialog) {
    localStorage.setItem('component', 'infracoes');
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    if (window.screen.width <= 768) {
      //  768px portrait
      this.mobile = true;
    } else {
      this.mobile = false;
    }
    this.dataInicial = this.tools.toDate(localStorage.getItem('dataInicial'));
    this.dataFinal = this.tools.toDate(localStorage.getItem('dataFinal'));
    this.geraDados();
  }

  setInicio(event: MatDatepickerInputEvent<Date>): void {
    this.loadingFiltro = true;
    this.dataInicial.setDate(event.value.getDate());
    localStorage.setItem('dataInicial', moment(this.dataInicial).format('DD/MM/YYYY'));
    this.geraDados();
  }

  setFim(event: MatDatepickerInputEvent<Date>): void {
    this.loadingFiltro = true;
    this.dataFinal.setDate(event.value.getDate());
    localStorage.setItem('dataFinal', moment(this.dataFinal).format('DD/MM/YYYY'));
    this.geraDados();
  }

  geraDados(): void{
    // total Multa e NIC
    this.multasService.getTipos().subscribe(sucesso => {
      this.progress = 12;
      this.geraTipo(sucesso.result);
      // grafico Multa e nic
      this.multasService.getTipos2().subscribe(tipos => {
        this.progress = 25;
        this.tipos = tipos.result;
        // grafico gravidade
        this.multasService.getGravidade().subscribe(gravidades => {
          this.progress = 37;
          this.gravidades = gravidades.result;
          // grafico descricoes
          this.multasService.getDescricao().subscribe(descricoes => {
            this.progress = 50;
            this.descricoes = descricoes.result;
            // grafico mensal
            this.multasService.getMensal().subscribe(mensais => {
              this.progress = 62;
              this.mensais = mensais.result;
              // tabela top 10
              this.multasService.getTop10().subscribe(top10 => {
                this.progress = 75;
                this.top10 = top10.result;
                // tabela gravissimas
                this.multasService.getGravissimas().subscribe(gravissimas => {
                  this.progress = 87;
                  this.gravissimas = gravissimas.result;
                  // grafico estado
                  this.multasService.getEstados().subscribe(estados => {
                    this.progress = 98;
                    this.estados = estados.result;
                    this.geraGraficos();
                    this.ok = true;
                  }, erro => {
                    console.log('erro ao obter estados');
                    console.log(erro);
                  });
                }, erro => {
                  console.log('erro ao obter gravissimas');
                  console.log(erro);
                });
              }, erro => {
                console.log('erro ao obter top 10');
                console.log(erro);
              });
            }, erro => {
              console.log('erro ao obter gráfico de dados mensais');
              console.log(erro);
            });
          }, erro => {
            console.log('erro ao obter gráfico de descricoes');
            console.log(erro);
          });
        }, erro => {
          console.log('erro ao obter gráfico de gravidade');
          console.log(erro);
        });
      }, erro => {
        console.log('erro ao obter gráfico de nic');
        console.log(erro);
      });
    }, erro => {
      console.log('Erro ao obter tipo de multa');
      console.log(erro);
    });
  }

  geraTipo(dados): void{
    if (dados.length > 0) {
      const nic = dados.filter(dado => dado.TIPO === 'NIC');
      this.nic = nic[0].TOTAL;
      const multa = dados.filter(dado => dado.TIPO !== 'NIC');
      let somaMulta = 0;
      for (const m of multa) {
        somaMulta += m.TOTAL;
      }
      this.multa = somaMulta;
    }
  }

  geraGraficos(): void{
    this.generateMotivoChart();
    this.generateGravidadeChart();
    this.generateDescricaoChart();
    this.generateMensalChart();
    this.generateMensalTable();
    this.generateTop10Table();
    this.generateGravissimasTable();
    this.generateEstadosChart();
  }

  // Gera o Gráfico de motivos
  generateMotivoChart(): void {
     this.tiposChart.type = 'ColumnChart';
     this.tiposChart.data = this.tipos;
     this.tiposChart.options = {
       animation: {
         duration: 1000,
         easing: 'out',
         startup: true,
       },
       chartArea: { width: '90%' },
       hAxis: {
         textStyle: {
           fontSize: 12,
         },
       },
       legend: { position: 'none' },
       backgroundColor: 'transparent',
       position: 'center',
       colors: [this.tools.randomColorGenerator()]
     };
  }

  // Gera o Gráfico de gravidade
  generateGravidadeChart(): void {
     this.gravidadeChart.type = 'BarChart';
     this.gravidadeChart.data = this.gravidades;
     this.gravidadeChart.options = {
       animation: {
         duration: 1000,
         easing: 'out',
         startup: true,
       },
       chartArea: { height: '90%', width: '20%' },
       vAxis: {
         textStyle: {
           fontSize: 18,
         },
       },
       legend: { position: 'none' },
       backgroundColor: 'transparent',
       position: 'center',
       colors: [this.tools.randomColorGenerator()]
     };
  }

  generateDescricaoChart(): void {
     this.descricaoChart.type = 'BarChart';
     this.descricaoChart.data = this.descricoes;
     this.descricaoChart.options = {
       animation: {
         duration: 1000,
         easing: 'out',
         startup: true,
       },
       chartArea: { height: '90%', width: '50%' },
       vAxis: {
         textStyle: {
           fontSize: 10,
         },
       },
       legend: { position: 'none' },
       backgroundColor: 'transparent',
       position: 'center',
       colors: [this.tools.randomColorGenerator()]
     };
  }

  // Gera o Gráfico de motivos
  generateMensalChart(): void {
    this.mensalChart.type = 'ColumnChart';
    this.mensalChart.data = this.mensais;
    this.mensalChart.options = {
      animation: {
        duration: 1000,
        easing: 'out',
        startup: true,
      },
      chartArea: { width: '90%' },
      hAxis: {
        textStyle: {
          fontSize: 12,
        },
      },
      legend: { position: 'none' },
      backgroundColor: 'transparent',
      position: 'center',
      colors: [this.tools.randomColorGenerator()]
    };
 }

  // Gera a tabela de meses
  generateMensalTable(): void {
    this.mensalChartTable.type = 'Table';
    this.mensalChartTable.data = this.mensais;
    this.mensalChartTable.names = [
      'Ano', 'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
      'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];
    this.mensalChartTable.options = {
      alternatingRowStyle: true,
      chartArea: { width: '100%' },
      backgroundColor: 'transparent',
      position: 'center',
      cssClassNames: {
        headerRow: 'table-header',
        tableRow : 'table-row',
        oddTableRow : 'table-row'
      }
    };
 }

  // Gera a tabela de top 10
  generateTop10Table(): void {
    this.top10ChartTable.type = 'Table';
    this.top10ChartTable.data = this.top10;
    this.top10ChartTable.names = [
      'Nome', 'Pontuação'
    ];
    this.top10ChartTable.options = {
      alternatingRowStyle: true,
      chartArea: { width: '100%' },
      backgroundColor: 'transparent',
      position: 'center',
      cssClassNames: {
        headerRow: 'table-header',
        tableRow : 'table-row',
        oddTableRow : 'table-row'
      },
      page: this.mobile ? 'enable' : 'disable',
      pagingSymbols: {
        prev: '   <   ',
        next: '   >   ',
      },
      pagingButtonsConfiguration: 'auto',
    };
 }

  // Gera a tabela de gravissimas
  generateGravissimasTable(): void {
    this.gravissimasChartTable.type = 'Table';
    this.gravissimasChartTable.data = this.gravissimas;
    this.gravissimasChartTable.names = [
      'Placa', 'Nome', 'Data', 'Descrição'
    ];
    this.gravissimasChartTable.options = {
      alternatingRowStyle: true,
      chartArea: { width: '90%' },
      backgroundColor: 'transparent',
      position: 'center',
      cssClassNames: {
        headerRow: 'table-header',
        tableRow : 'table-row',
        oddTableRow : 'table-row'
      },
      page: this.mobile ? 'enable' : 'disable',
      pagingSymbols: {
        prev: '   <   ',
        next: '   >   ',
      },
      pagingButtonsConfiguration: 'auto',
    };
 }

 generateEstadosChart(): void {
  this.estadosChart.type = 'GeoChart';
  this.estadosChart.data = this.estados;
  this.estadosChart.options = {
    region: 'BR',
    resolution: 'provinces',
  };
  this.loadingFiltro = false;
}

abrirDetalhes(dado: string): void {
  let data: any;
  let names: string[] = [];

  switch (dado) {
    case 'status':
      data = this.tipos;
      names = ['Tipo', 'Quantidade'];
      break;
    case 'gravidade':
      data = this.gravidades;
      names = ['Motivo', 'Quantidade'];
      break;
    case 'descricao':
      data = this.descricoes;
      names = ['Gerencial', 'Quantidade'];
      break;
    case 'mapa':
      data = this.estados;
      names = ['Estado', 'Quantidade'];
      break;
  }

  this.dialog.open(DetalhesHomeComponent, {
    width: 'wrap',
    height: 'wrap',
    panelClass: 'dialog-transparent',
    data: {
      thisData: data,
      thisNames: names,
    },
  });
}

abrirAll(): void {
  this.dialog.open(AllServicosComponent, {
    width: '98vw',
    height: 'wrap',
    panelClass: 'dialog-transparent',
    data: {
      tipo: 'infracao',
    },
  });
}

isFullScreen(): boolean {
  return (
    (document.fullscreenElement && document.fullscreenElement !== null) ||
    (document.fullscreenElement && document.fullscreenElement !== null)
  );
}

enterFS(id): void {
  const page = document.getElementById(id);
  if (page.requestFullscreen) {
    page.requestFullscreen();
  } else if (page.requestFullscreen) {
    page.requestFullscreen();
  }
}

exitFS(): Promise<void> {
  if (document.exitFullscreen) {
    return document.exitFullscreen();
  } else if (document.exitFullscreen) {
    return document.exitFullscreen();
  }
}

toggleFS(id): void {
  if (!this.isFullScreen()) {
    this.full = true;
    this.enterFS(id);
  } else {
    this.full = false;
    this.exitFS();
  }
}

}
