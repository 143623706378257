import { LoginService } from 'src/app/login/login.service';
import { GeradorService } from './../gerador.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'pt-titulo',
  templateUrl: './titulo.component.html',
  styleUrls: ['./titulo.component.css']
})
export class TituloComponent implements OnInit {

  @Input() titulo: string;
  @Input() id: number;

  token: string;
  codigofun: number;

  constructor(private snackBar: MatSnackBar,
              private geradorService: GeradorService,
              private loginService: LoginService) { }

  ngOnInit(): void {
    const token = this.loginService.getToken();
    const codigofun = JSON.parse(atob(token.split('.')[1])).codigofun;
  }

  showAlert(): void {
    this.snackBar.open('Deseja excluir esse item?', 'Sim', {
      duration: 2000,
    });
    this.snackBar._openedSnackBarRef.onAction().subscribe( () => {
      this.geradorService.pullRelatorios(this.id, this.codigofun);
    });
  }
}
