import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { ComponentModel } from './component.model';
import { environment } from 'src/environments/environment';

import { GeradorSQL } from './gerador.sql';

const BACKEND_INSERT_URL = environment.apiUrl + '/insert';
const BACKEND_CONSULTA_URL = environment.apiUrl + '/consulta';

@Injectable({
  providedIn: 'root'
})
export class GeradorService {

  relatorios: ComponentModel[] = [];
  relatoriosUpdated = new Subject<any>();
  database = localStorage.getItem('gerencial_db');

  constructor(private geradorSQL: GeradorSQL,
              private http: HttpClient) {}

  pushRelatorios(codigofun: number, component: any, titulo: string, width: string): void {
    const sql = this.geradorSQL.insertComponentSQL(codigofun, component, titulo, width);
    this.http.post<{ result: any[] }>(BACKEND_INSERT_URL + '/insert', {sql, db: this.database}).subscribe(resultado => {
      this.getRelatorios(codigofun);
      console.log('Dado inserido com sucesso');
    }, erro => {
      console.log('Erro ao inserir dados');
    });
  }

  pullRelatorios(id: number, codigofun: number): void {
    const sql = this.geradorSQL.deleteComponent(id, codigofun);
    this.http.post<{ result: any[] }>(BACKEND_INSERT_URL + '/insert', {sql, db: this.database}).subscribe(resultado => {
      console.log('Dado excluído com sucesso');
      this.getRelatorios(codigofun);
    }, erro => {
      console.log('Erro ao excluir dado');
    });
  }

  getRelatorios(codigofun: number): void{
    const sql = this.geradorSQL.getComponentSQL(codigofun);
    this.http.post<{ result: any[] }>(BACKEND_CONSULTA_URL + '/label', {sql, db: this.database}).subscribe(resultado => {
      console.log('Dados obtidos com sucesso');
      this.relatorios = resultado.result;
      this.relatoriosUpdated.next([...this.relatorios]);
    }, erro => {
      console.log('Erro ao obter dados');
    });
  }

  getRelatoriosListener(): Observable<ComponentModel[]> {
    return this.relatoriosUpdated.asObservable();
  }

}
