import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class GeradorSQL {

  insertComponentSQL(codigofun: number, component: any, titulo: string, width: string): string {
    return `insert into painel_gerencial (id, codigofun, component, titulo, width, ordem) values (
      nvl((select max(id) + 1 from painel_gerencial),0),
      ${codigofun}, '${component}', '${titulo}', ${width},
      nvl((select max(ordem) +1 from painel_gerencial where codigofun = ${codigofun} ),0)
    )`;
  }

  getComponentSQL(codigofun: number): string {
    return `select * from painel_gerencial where codigofun = ${codigofun}
            order by ordem`;
  }

  deleteComponent(id: number, codigofun: number): string{
    return `delete from painel_gerencial where codigofun = ${codigofun} and id = ${id}`;
  }

}
