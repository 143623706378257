import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import { ServicosService } from './servicos.service';
import { Login } from 'src/app/login/login.model';
import { LoginService } from 'src/app/login/login.service';
import { Status } from '../atendimento/status.model';
import { ChartModel } from '../../charts.model';
import { TopDez } from './servicos.model';
import { MatDialog } from '@angular/material/dialog';
import { AllServicosComponent } from '../home/allServicos/allServicos.component';
import { Router, NavigationEnd } from '@angular/router';
import { Tools } from '../../tools';
import { DetalhesHomeComponent } from '../home/detalhes/detalhesHome.component';

@Component({
  selector: 'pt-servicos',
  templateUrl: './servicos.component.html',
  styleUrls: ['./servicos.component.css'],
})
export class ServicosComponent implements OnInit, OnDestroy {
  mySubscription: any;

  opcao = 'os';
  totalOS = 0;
  progress = 0;

  dataInicial: Date;
  dataFinal: Date;

  ok = false;
  mobile: boolean;

  colunas: string[] = [];

  token: string;

  full = false;

  loadingFiltro = true;

  // status charts
  statusOS: Status[] = [];
  statusChart: ChartModel = { data: null, type: null, options: null };
  // motivo charts
  motivos: Status[] = [];
  motivoChart: ChartModel = { data: null, type: null, options: null };
  // gerencial charts
  gerenciais: Status[] = [];
  gerencialChart: ChartModel = { data: null, type: null, options: null };
  // porcentagem charts
  porcentagem: number;
  porcentagemChart: ChartModel = { data: null, type: null, options: null };
  // idf charts
  idf: number;
  idfChart: ChartModel = { data: null, type: null, options: null };
  // top dez charts
  oficinas: TopDez[] = [];
  topDezChart: ChartModel = { data: null, type: null, options: null };
  // top dez tabela
  topDezTable: ChartModel = { data: null, type: null, options: null };
  // all
  allData = [];
  // labels
  labels: string[] = [];
  // estado charts
  estados: Status[] = [];
  estadosChart: ChartModel = { data: null, type: null, options: null };

  usuarioLogado: Login = {
    codigofun: null,
    nome: null,
    empresa: null,
    grupoAcesso: null,
  };

  myOpts = {
    separator: '.',
  };

  constructor(
    private loginService: LoginService,
    private servicosService: ServicosService,
    private dialog: MatDialog,
    private router: Router,
    private tools: Tools
  ) {
    localStorage.setItem('component', 'servicos');
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    if (window.screen.width <= 768) {
      // 768px portrait
      this.mobile = true;
    } else {
      this.mobile = false;
    }
    this.getToken();
    this.getUsuarioLogado();

    this.dataInicial = this.tools.toDate(localStorage.getItem('dataInicial'));
    this.dataFinal = this.tools.toDate(localStorage.getItem('dataFinal'));
    this.geraDados();
  }

  // Pega o token que contém os dados do usuário
  getToken(): void {
    this.token = this.loginService.getToken();
  }

  // Preenche os dados do usuário logado pelo token
  getUsuarioLogado(): void {
    this.usuarioLogado.codigofun = JSON.parse(
      atob(this.token.split('.')[1])
    ).codigofun;
    this.usuarioLogado.nome = JSON.parse(atob(this.token.split('.')[1])).nome;
    this.usuarioLogado.empresa = JSON.parse(
      atob(this.token.split('.')[1])
    ).empresa;
    this.usuarioLogado.grupoAcesso = JSON.parse(
      atob(this.token.split('.')[1])
    ).grupoAcesso;
  }

  setInicio(event: MatDatepickerInputEvent<Date>): void {
    this.loadingFiltro = false;
    this.dataInicial.setDate(event.value.getDate());
    localStorage.setItem('dataInicial', moment(this.dataInicial).format('DD/MM/YYYY'));
    this.geraDados();
  }

  setFim(event: MatDatepickerInputEvent<Date>): void {
    this.loadingFiltro = false;
    this.dataFinal.setDate(event.value.getDate());
    localStorage.setItem('dataFinal', moment(this.dataFinal).format('DD/MM/YYYY'));
    this.geraDados();
  }

  somaOS(): void {
    this.totalOS = 0;
    if (this.statusOS.length > 0){
      for (const status of this.statusOS){
        this.totalOS += status[1];
      }
    }
  }

  trocaFiltro(filtro: string): void {
    this.loadingFiltro = false;
    this.opcao = filtro;
    localStorage.setItem('opcao', this.opcao);
    this.geraDados();
  }

  geraDados(): void {
    this.loadingFiltro = true;
    this.progress = 0;

    // motivo
    this.servicosService
      .generateMotivo().subscribe(motivos => {
          this.motivos = motivos.result;
          this.progress = 25;
          // gerencial
          this.servicosService.generateGerencial().subscribe(gerenciais => {
                this.gerenciais = gerenciais.result;
                this.progress = 50;
                // staos
                this.servicosService.generateStaOS().subscribe(statusOS => {
                      this.statusOS = statusOS.result;
                      this.progress = 60;
                      // idf
                      this.servicosService.generateIDF().subscribe(idf => {
                          this.idf = idf.result[0][1];
                          this.progress = 80;
                          // top 10
                          this.servicosService.generateTopDez().subscribe(oficinas => {
                              this.oficinas = oficinas.result;
                              this.progress = 99;
                              // estados
                              this.servicosService.generatEstados().subscribe(estados => {
                                  this.estados = estados.result;
                                  this.progress = 100;
                                  this.somaOS();
                                  this.geraGraficos();
                                  this.ok = true;
                                });
                            });
                        });
                    },
                    (erro) => {
                      console.log(erro);
                    }
                  );
              },
              (erro) => {
                console.log(erro);
              }
            );
        },
        (erro) => {
          console.log(erro);
        }
      );
  }

  geraGraficos(): void {
    this.generateStatusChart();
    this.generateMotivoChart();
    this.generateGerencialChart();
    this.generatePorcentagemChart();
    this.generateIDFChart();
    this.generateTopDezChart();
    this.generateTopDezTable();
    this.generateEstadosChart();
  }

  // Gera o Gráfico de status
  generateStatusChart(): void {
    this.statusChart.type = 'PieChart';
    this.statusChart.data = this.statusOS;
    this.statusChart.options = {
      pieHole: 0.4,
      chartArea: { width: this.mobile ? '60%' : '90%' },
      legend: { position:  this.mobile ? 'top' : 'right',
                maxLines: 2 },
      backgroundColor: 'transparent',
      position: 'left',
    };
  }

  // Gera o Gráfico de motivos
  generateMotivoChart(): void {
    this.motivoChart.type = 'ColumnChart';
    this.motivoChart.data = this.motivos;
    this.motivoChart.options = {
      animation: {
        duration: 1000,
        easing: 'out',
        startup: true,
      },
      chartArea: { width: '90%' },
      hAxis: {
        textStyle: {
          fontSize: 8,
        },
      },
      legend: { position: 'none' },
      backgroundColor: 'transparent',
      position: 'center',
      colors: [this.tools.randomColorGenerator()]
    };
  }

  // Gera o Gráfico de gerencial
  generateGerencialChart(): void {
    this.gerencialChart.type = 'BarChart';
    this.gerencialChart.data = this.gerenciais;
    this.gerencialChart.options = {
      animation: {
        duration: 1000,
        easing: 'out',
        startup: true,
      },
      chartArea: { height: '90%' },
      hAxis: {
        textStyle: {
          fontSize: 8,
        },
      },
      legend: { position: 'none' },
      backgroundColor: 'transparent',
      position: 'center',
      colors: [this.tools.randomColorGenerator()]
    };
  }

  // Gera o Gráfico de porcentagem de preventiva
  generatePorcentagemChart(): void {
    let porcentagem = 0;
    let preventiva = 0;
    let soma = 0;

    if (this.statusOS.length > 0) {
      if (this.statusOS.filter((motivo) => motivo[0] === 'PREVENTIVA')){
        const valor = this.statusOS.filter((motivo) => motivo[0] === 'PREVENTIVA');
        if (valor.length > 0) {
          preventiva = valor[0][1];
          soma += valor[0][1];
        }
      }
      if (this.statusOS.filter((motivo) => motivo[0] === 'CORRETIVA')){
        const valor = this.statusOS.filter((motivo) => motivo[0] === 'CORRETIVA');
        if (valor.length > 0) {
          soma += valor[0][1];
        }
      }
      if (this.statusOS.filter((motivo) => motivo[0] === 'ACIDENTE ')){
        const valor = this.statusOS.filter((motivo) => motivo[0] === 'ACIDENTE ');
        if (valor.length > 0) {
          soma += valor[0][1];
        }
      }

      preventiva <= 0 ? preventiva = 0 : preventiva = preventiva;
      soma <= 0 ? porcentagem = 100 : porcentagem = Math.trunc(preventiva / soma * 100);
    }


    this.porcentagemChart.data = [
        ['', { v: porcentagem, f: porcentagem + '%' }],
    ];
    this.porcentagemChart.type = 'Gauge';
    this.porcentagemChart.options = {
      greenFrom: 50,
      greenTo: 100,
      redFrom: 0,
      redTo: 20,
      yellowFrom: 20,
      yellowTo: 50,
      minorTicks: 5,
      position: 'center',
    };
  }

  // Gera o Gráfico de IDF
  generateIDFChart(): void {
    this.idfChart.type = 'Gauge';
    this.idfChart.data = [['', { v: this.idf, f: this.idf + '%' }]];
    this.idfChart.options = {
      greenFrom: 95,
      greenTo: 100,
      redFrom: 0,
      redTo: 80,
      yellowFrom: 80,
      yellowTo: 95,
      minorTicks: 5,
      position: 'center',
    };
  }

  // Gera o Gráfico de Top 10
  generateTopDezChart(): void {
    const chartData = [];

    for (const oficina of this.oficinas){
      const objeto = [oficina[1], oficina[5]];
      chartData.push(objeto);
    }

    this.topDezChart.type = 'ColumnChart';
    this.topDezChart.data = chartData;
    this.topDezChart.options = {
      animation: {
        duration: 1000,
        easing: 'out',
        startup: true,
      },
      chartArea: { width: '90%' },
      hAxis: {
        textStyle: {
          fontSize: 8,
        },
      },
      legend: { position: 'none' },
      backgroundColor: 'transparent',
      position: 'center',
      colors: [this.tools.randomColorGenerator()]
    };
  }

  generateTopDezTable(): void {
    const chartData = [];

    for (const oficina of this.oficinas){
      const objeto = [
        oficina[0],
        oficina[1],
        oficina[2],
        oficina[3],
        Intl.NumberFormat('pt-BR', { maximumFractionDigits: 0 }).format(
          oficina[4]
        ),
        Intl.NumberFormat('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          style: 'currency',
          currency: 'BRL',
        }).format(oficina[5]),
      ];
      chartData.push(objeto);
    }
    this.topDezTable.type = 'Table';
    this.topDezTable.data = chartData;
    this.topDezTable.names = [
      'Posição',
      'Estabelecimento',
      'Estado',
      'Atividade',
      'Qnt OS',
      'Valor Total',
    ];
    this.topDezTable.options = {
      alternatingRowStyle: true,
      chartArea: { width: '90%' },
      backgroundColor: 'transparent',
      position: 'center',
      cssClassNames: {
        headerRow: 'table-header',
        tableRow : 'table-row',
        oddTableRow : 'table-row'
      }
    };
  }

  generateEstadosChart(): void {
    this.estadosChart.type = 'GeoChart';
    this.estadosChart.data = this.estados;
    this.estadosChart.options = {
      region: 'BR',
      resolution: 'provinces',
      chartArea: { width: '70%' },
    };
    this.loadingFiltro = false;
  }

  abrirDetalhes(dado: string): void {
    let data: any;
    let names: string[] = [];

    switch (dado) {
      case 'status':
        data = this.statusOS;
        names = ['Status', 'Quantidade'];
        break;
      case 'motivo':
        data = this.motivos;
        names = ['Motivo', 'Quantidade'];
        break;
      case 'gerencial':
        data = this.gerenciais;
        names = ['Gerencial', 'Quantidade'];
        break;
      case 'idf':
        data = this.idf;
        names = ['IDF'];
        break;
      case 'porcentagem':
        data = this.porcentagem;
        names = ['% de Preventivas'];
        break;
      case 'top10':
        data = this.topDezTable;
        names = this.topDezChart.names;
        break;
      case 'estado':
        data = this.estados;
        names = ['Estado', 'Quantidade'];
        break;
    }

    this.dialog.open(DetalhesHomeComponent, {
      width: 'wrap',
      height: 'wrap',
      panelClass: 'dialog-transparent',
      data: {
        thisData: data,
        thisNames: names,
      },
    });
  }

  abrirAll(): void {
    this.dialog.open(AllServicosComponent, {
      width: '98vw',
      height: 'wrap',
      panelClass: 'dialog-transparent',
      data: {
        tipo: 'os'
      },
    });
  }

  isFullScreen(): boolean {
    return (
      (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.fullscreenElement && document.fullscreenElement !== null)
    );
  }

  enterFS(id): void {
    const page = document.getElementById(id);
    if (page.requestFullscreen) {
      page.requestFullscreen();
    } else if (page.requestFullscreen) {
      page.requestFullscreen();
    }
  }

  exitFS(): Promise<void> {
    if (document.exitFullscreen) {
      return document.exitFullscreen();
    } else if (document.exitFullscreen) {
      return document.exitFullscreen();
    }
  }

  toggleFS(id: string): void {
    if (!this.isFullScreen()) {
      this.full = true;
      this.enterFS(id);
    } else {
      this.full = false;
      this.exitFS();
    }
  }
}
