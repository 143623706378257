import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class MultasSQL {

  getMultasSQL(empresa: string, dataInicial: string, dataFinal: string): string {
    return `select * from PAINEL_MULTA where codigocli in ( ${empresa} )
        and trunc(dtinfracao) between to_date('${dataInicial}','dd/mm/yyyy')
        and to_date('${dataFinal}','dd/mm/yyyy')`;
  }

  getTipoSQL(empresa: string, dataInicial: string, dataFinal: string): string {
    return `select tipo, count (*) as total from PAINEL_MULTA where codigocli in ( ${empresa} )
    and trunc(dtinfracao) between to_date('${dataInicial}','dd/mm/yyyy')
    and to_date('${dataFinal}','dd/mm/yyyy') GROUP BY tipo`;
  }

  getResumoGravidade(dias: number, empresa: string): string {
    return `select nvl(classif,'LEVE') as tipo, count (*) as total from PAINEL_MULTA
               where codigocli in ( ${empresa} ) and trunc(dtinfracao) >= trunc(sysdate) - ${dias}
       GROUP BY nvl(classif,'LEVE')`;
  }

  getGravidadeSQL(empresa: string, dataInicial: string, dataFinal: string): string {
    return `select nvl(classif,'LEVE') as tipo, count (*) as total from PAINEL_MULTA where codigocli in ( ${empresa} )
    and trunc(dtinfracao) between to_date('${dataInicial}','dd/mm/yyyy')
    and to_date('${dataFinal}','dd/mm/yyyy') GROUP BY nvl(classif,'LEVE') order by 2`;
  }

  getDescricaoSQL(empresa: string, dataInicial: string, dataFinal: string): string {
    return `select CLASSIFICACAO, count (*) as total from PAINEL_MULTA where codigocli in ( ${empresa} )
    and trunc(dtinfracao) between to_date('${dataInicial}','dd/mm/yyyy')
    and to_date('${dataFinal}','dd/mm/yyyy') GROUP BY CLASSIFICACAO order by 2 desc`;
  }

  getMensalSQL(empresa: string, dataInicial: string, dataFinal: string): string {
    return `SELECT ANO, NVL(JANEIRO,0), NVL(FEVEREIRO,0), NVL(MARCO,0), NVL(ABRIL,0), NVL(MAIO,0), NVL(JUNHO,0), NVL(JULHO,0),
       NVL(AGOSTO,0), NVL(SETEMBRO,0), NVL(OUTUBRO,0), NVL(NOVEMBRO,0), NVL(DEZEMBRO,0) FROM (
       SELECT to_char(DTINFRACAO , 'yyyy') AS ano, to_char(DTINFRACAO , 'mm') AS mes, count(*) AS total FROM PAINEL_MULTA pm
       where codigocli in ( ${empresa} )
       and trunc(dtinfracao) between to_date('${dataInicial}','dd/mm/yyyy')
       and to_date('${dataFinal}','dd/mm/yyyy')
       GROUP BY to_char(DTINFRACAO , 'yyyy'), to_char(DTINFRACAO , 'mm')) pivot(sum(total)
       FOR mes IN ('01' AS janeiro,'02' AS fevereiro,'03' AS marco,'04' AS abril,'05' AS maio,'06' AS junho,'07' AS julho,'08' AS agosto,
       '09' AS setembro,'10' AS outubro,'11' AS novembro,'12' AS dezembro)) ORDER BY 1`;
  }

  getTop10(empresa: string, dataInicial: string, dataFinal: string): string {
    return `SELECT * FROM (
       SELECT NOME, SUM(PONTUACAO) AS PONTOS FROM PAINEL_MULTA
       where codigocli in ( ${empresa} )
       and trunc(dtinfracao) between to_date('${dataInicial}','dd/mm/yyyy')
       and to_date('${dataFinal}','dd/mm/yyyy')
       GROUP BY NOME
       ORDER BY 2 DESC
       ) WHERE ROWNUM <= 20`;
  }

  getGravissima(empresa: string, dataInicial: string, dataFinal: string): string {
    return `SELECT PLACA, NOME, TO_CHAR(DTINFRACAO,'DD/MM/YYYY') AS DATA, CLASSIFICACAO
       FROM PAINEL_MULTA
       where codigocli in ( ${empresa} )
       and trunc(dtinfracao) between to_date('${dataInicial}','dd/mm/yyyy')
       and to_date('${dataFinal}','dd/mm/yyyy')
       AND (CLASSIFICACAO LIKE '%DIRIGIR%LCOOL%' OR
       CLASSIFICACAO LIKE '%TRANSPOR%BLOQUEIO%' OR
       CLASSIFICACAO LIKE '%VEL%SU%MAIS%50%' OR
       CLASSIFICACAO LIKE '%RECUSA%BAF%')
       ORDER BY DTINFRACAO`;
  }

  getEstados(empresa: string, dataInicial: string, dataFinal: string): string {
    return `SELECT 'BR-' || nvl(ESTADO,'SP'), count(*) as total
       FROM PAINEL_MULTA
       where codigocli in ( ${empresa} )
       and trunc(dtinfracao) between to_date('${dataInicial}','dd/mm/yyyy')
       and to_date('${dataFinal}','dd/mm/yyyy')
       GROUP BY 'BR-' || nvl(ESTADO,'SP')`;
  }

  allSQL(dataInicial: string, dataFinal: string, empresa: string): string {
    return `select INFRACAO, POSICAO , to_char(DTINFRACAO ,'dd/mm/yyyy hh24:mi') AS dtinfracao,
            placa, tipo, classif, enquadramento, classificacao, pontuacao, nome, codigocli,
            GERENCIAL, ESTADO , VALOR, TO_CHAR(DTLANCAMENTO ,'dd/mm/yyyy hh24:mi') AS dtlancamento
            from PAINEL_MULTA
            where codigocli in  ( ${empresa} )
            and trunc(dtinfracao) >= to_date( '${dataInicial}' , 'dd/mm/yyyy')
            and trunc(dtinfracao) <= to_date( '${dataFinal}' , 'dd/mm/yyyy')`;
  }

  labelsSQL(): string {
    const grupo = localStorage.getItem('gerencial_db').toUpperCase();
    return `SELECT column_name FROM all_tab_cols
            WHERE table_name = 'PAINEL_MULTA' AND OWNER = '${grupo === 'ALELO' ? environment.banco_alelo : environment.banco_tfleet }'
            ORDER BY COLUMN_ID`;
  }

  getValorTipoSQL(empresa: string, dataInicial: string, dataFinal: string): string {
    return `SELECT nvl(TIPO, 'MULTA') AS TIPO,  sum(VALOR) AS VALOR, count(*) as QUANTIDADE
            FROM PAINEL_MULTA
            where codigocli in ( ${empresa} )
            and trunc(dtinfracao) between to_date('${dataInicial}','dd/mm/yyyy')
            and to_date('${dataFinal}','dd/mm/yyyy')
            GROUP BY nvl(TIPO, 'MULTA')`;
  }

  getTop10PlacaValorSQL(empresa: string, dataInicial: string, dataFinal: string): string {
    return `SELECT * FROM (
            SELECT PLACA, sum(VALOR) AS VALOR
            FROM PAINEL_MULTA
            where codigocli in ( ${empresa} )
            and trunc(dtinfracao) between to_date('${dataInicial}','dd/mm/yyyy')
            and to_date('${dataFinal}','dd/mm/yyyy')
            GROUP BY PLACA ORDER BY 2 DESC
            ) WHERE rownum <= 10`;
  }

  getTop10ClassificacaoValorSQL(empresa: string, dataInicial: string, dataFinal: string): string {
    return `SELECT * FROM (
            SELECT CLASSIFICACAO, sum(VALOR) AS VALOR
            FROM PAINEL_MULTA
            where codigocli in ( ${empresa} )
            and trunc(dtinfracao) between to_date('${dataInicial}','dd/mm/yyyy')
            and to_date('${dataFinal}','dd/mm/yyyy')
            GROUP BY CLASSIFICACAO ORDER BY 2 DESC
            ) WHERE rownum <= 10`;
  }

  labelsSQLR(): string{
    const grupo = localStorage.getItem('gerencial_db').toUpperCase();
    return `SELECT column_name FROM all_tab_cols
            WHERE table_name = 'PAINEL_MULTA' AND OWNER = '${grupo === 'ALELO' ? environment.banco_alelo : environment.banco_tfleet }'
            ORDER BY column_name`;
  }


}
