import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ServicoSQL {
  resumoSQL(dias: number, empresa: string): string {
    return `select sum(case when o.dtfechamento is not null then 1 else 0 end) as fechados,
              sum(case when o.dtfechamento is null then 1 else 0 end) as abertos,
              count(*) as total
              from lordserv o
              inner join cgfrota f on f.placa = o.placa
              inner join cgclifor c on c.codigocli = f.codigopropri
              where o.staclasse in ('I','O')
              and trunc(o.dtabertura) >= trunc(sysdate) - ${dias}
              and f.codigopropri in  ( ${empresa} )`;
  }

  statusSQL(dias: number, empresa: string): string {
    return `select status, count(*) as total from(
       select case when o.dtcotacao is null then 'aguardando_orcamento'
       when o.dtcotacao is not null and o.dtavaliacao is null then 'aguardando_avaliacao'
       when o.dtcotacao is not null and o.dtavaliacao is not null
       and o.dtenvioaprovcliente is not null and o.dtretornoaprovcliente is null and o.dtaprovacao is null then 'aguardando_aprovacao_cliente'
       when o.dtcotacao is not null and o.dtavaliacao is not null
       and o.dtenvioaprovcliente is not null and o.dtretornoaprovcliente is not null and o.dtaprovacao is null then 'aguardando_aprovacao_tfleet'
       when o.dtcotacao is not null and o.dtavaliacao is not null
       and o.dtenvioaprovcliente is null and o.dtaprovacao is null then 'aguardando_aprovacao_tfleet'
       when o.dtaprovacao is not null then 'servico_em_execucao' else 'outro' end as status
       from lordserv o
       inner join cgfrota f on f.placa = o.placa
       inner join cgclifor c on c.codigocli = f.codigopropri
       inner join cggrgere g on g.codigogger = c.codigoger
       where o.dtfechamento is null
       and o.staclasse in ('I','O')
       and trunc(o.dtabertura) >= trunc(sysdate) - ${dias}
       and f.codigopropri in  ( ${empresa} )) group by status` ;
  }

  statusOSSQL(dataInicial: string, dataFinal: string, empresa: string): string {
    return `select status, count(*) as total from PAINEL_OS p
       where p.codigofiltro in  ( ${empresa} )
       and to_date(p.dt_fechamento,'dd/mm/yyyy') > to_date( '${dataInicial}' , 'dd/mm/yyyy')
       and to_date(p.dt_fechamento,'dd/mm/yyyy') < to_date( '${dataFinal}' , 'dd/mm/yyyy')
       group by status order by 1`;
  }

  statusNDSQL(dataInicial: string, dataFinal: string, empresa: string): string {
    return `select status, count(*) as total from PAINEL_OS p
       where p.codigofiltro in  ( ${empresa} )
       and to_date(p.dtemissaond ,'dd/mm/yyyy') > to_date( '${dataInicial}' , 'dd/mm/yyyy')
       and to_date(p.dtemissaond ,'dd/mm/yyyy') < to_date( '${dataFinal}' , 'dd/mm/yyyy')
       group by status order by 1`;
  }

  motivoOSSQL(dataInicial: string, dataFinal: string, empresa: string): string {
    return `select motivo, count(*) as total from PAINEL_OS p
       where p.codigofiltro in  ( ${empresa} )
       and to_date(p.dt_fechamento,'dd/mm/yyyy') > to_date( '${dataInicial}' , 'dd/mm/yyyy')
       and to_date(p.dt_fechamento,'dd/mm/yyyy') < to_date( '${dataFinal}' , 'dd/mm/yyyy')
       group by motivo order by 1`;
  }

  motivoNDSQL(dataInicial: string, dataFinal: string, empresa: string): string {
    return `select motivo, count(*) as total from PAINEL_OS p
       where p.codigofiltro in  ( ${empresa} )
       and to_date(p.dtemissaond ,'dd/mm/yyyy') > to_date( '${dataInicial}' , 'dd/mm/yyyy')
       and to_date(p.dtemissaond ,'dd/mm/yyyy') < to_date( '${dataFinal}' , 'dd/mm/yyyy')
       group by motivo order by 1`;
  }

  gerencialOSSQL(dataInicial: string, dataFinal: string, empresa: string): string {
    return `select gerencial, count(*) as total from PAINEL_OS p
       where p.codigofiltro in  ( ${empresa} )
       and to_date(p.dt_fechamento,'dd/mm/yyyy') > to_date( '${dataInicial}' , 'dd/mm/yyyy')
       and to_date(p.dt_fechamento,'dd/mm/yyyy') < to_date( '${dataFinal}' , 'dd/mm/yyyy')
       group by gerencial order by 1`;
  }

  gerencialNDSQL(dataInicial: string, dataFinal: string, empresa: string): string {
    return `select gerencial, count(*) as total from PAINEL_OS p
       where p.codigofiltro in  ( ${empresa} )
       and to_date(p.dtemissaond ,'dd/mm/yyyy') > to_date( '${dataInicial}' , 'dd/mm/yyyy')
       and to_date(p.dtemissaond ,'dd/mm/yyyy') < to_date( '${dataFinal}' , 'dd/mm/yyyy')
       group by gerencial order by 1`;
  }

  IDFOSSQL(dataInicial: string, dataFinal: string, empresa: string): string {
    return `select 'IDF_GERAL',
              100 * trunc(1 - parados / (select count(*) as qnt
                from cgfrota f
                inner join cgclifor c on c.codigocli = f.codigopropri
                inner join cggrgere g on g.codigogger = c.codigoger
                where f.situacao = 'A'
                and f.codigopropri in ( ${empresa} )), 2) as idf
                from (select dias / (maxdt - mindt) as parados
                from (select max(to_date(p.dt_fechamento, 'dd/mm/yyyy')) as maxdt,
                min(to_date(p.dt_fechamento, 'dd/mm/yyyy')) as mindt,
                sum(p.dias_parados_entrada) as dias
                from PAINEL_OS p
                where p.codigofiltro in ( ${empresa} )
                and to_date(p.dt_fechamento,'dd/mm/yyyy') >= to_date('${dataInicial}', 'dd/mm/yyyy')
                and to_date(p.dt_fechamento,'dd/mm/yyyy') <= to_date('${dataFinal}', 'dd/mm/yyyy')) p)`;
  }

  IDFNDSQL(dataInicial: string, dataFinal: string, empresa: string): string {
    return `select 'IDF_GERAL',
       100 * trunc(1 - parados / (select count(*) as qnt
         from cgfrota f
         inner join cgclifor c on c.codigocli = f.codigopropri
         inner join cggrgere g on g.codigogger = c.codigoger
         where f.situacao = 'A'
         and f.codigopropri in ( ${empresa} )
         ),
         2) as idf
         from (select dias / (maxdt - mindt) as parados
         from (select max(to_date(p.dt_fechamento, 'dd/mm/yyyy')) as maxdt,
         min(to_date(p.dt_fechamento, 'dd/mm/yyyy')) as mindt,
         sum(p.dias_parados_entrada) as dias
         from PAINEL_OS p
         where p.codigofiltro in ( ${empresa} )
         and to_date(p.dtemissaond ,'dd/mm/yyyy') >= to_date('${dataInicial}', 'dd/mm/yyyy')
         and to_date(p.dtemissaond ,'dd/mm/yyyy') <= to_date('${dataFinal}', 'dd/mm/yyyy')) p)`;
  }

  topDezOSSQL(dataInicial: string, dataFinal: string, empresa: string): string {
    return `select ROWNUM, estabelecimento, estado, atividade, qnt_os, valor from (
       select estabelecimento, estado, atividade, count(ordem_servico) as qnt_os, sum(valor_os) as valor from PAINEL_OS p
       where codigofiltro in  ( ${empresa} )
       and to_date(p.dt_fechamento,'dd/mm/yyyy') > to_date( '${dataInicial}' , 'dd/mm/yyyy')
       and to_date(p.dt_fechamento,'dd/mm/yyyy') < to_date( '${dataFinal}' , 'dd/mm/yyyy')
       group by estabelecimento, estado, atividade
       order by valor desc
       ) where ROWNUM <= 10`;
  }

  topDezNDSQL(dataInicial: string, dataFinal: string, empresa: string): string {
    return `select ROWNUM, estabelecimento, estado, atividade, qnt_os, valor from (
       select estabelecimento, estado, atividade, count(ordem_servico) as qnt_os, sum(valor_os) as valor from PAINEL_OS p
       where codigofiltro in  ( ${empresa} )
       and dtemissaond > to_date( '${dataInicial}' , 'dd/mm/yyyy')
       and dtemissaond < to_date( '${dataFinal}' , 'dd/mm/yyyy')
       group by estabelecimento, estado, atividade
       order by valor desc
       ) where ROWNUM <= 10`;
  }

  estadosOSSQL(dataInicial: string, dataFinal: string, empresa: string): string {
    return `select 'BR-' || nvl(ESTADO,'SP') as estado, count(*) as total from PAINEL_OS p
       where p.codigofiltro in  ( ${empresa} )
       and to_date(p.dt_fechamento,'dd/mm/yyyy') > to_date( '${dataInicial}' , 'dd/mm/yyyy')
       and to_date(p.dt_fechamento,'dd/mm/yyyy') < to_date( '${dataFinal}' , 'dd/mm/yyyy')
       group by 'BR-' || nvl(ESTADO,'SP') order by 1`;
  }

  estadosNDSQL(dataInicial: string, dataFinal: string, empresa: string): string {
    return `select 'BR-' || nvl(ESTADO,'SP') as estado, count(*) as total from PAINEL_OS p
       where p.codigofiltro in  ( ${empresa} )
       and to_date(p.dtemissaond ,'dd/mm/yyyy') > to_date( '${dataInicial}' , 'dd/mm/yyyy')
       and to_date(p.dtemissaond ,'dd/mm/yyyy') < to_date( '${dataFinal}' , 'dd/mm/yyyy')
       group by 'BR-' || nvl(ESTADO,'SP') order by 1`;
  }

  allOSSQL(dataInicial: string, dataFinal: string, empresa: string): string {
    return `select * from PAINEL_OS p
       where p.codigofiltro in  ( ${empresa})
       and to_date(p.dt_fechamento,'dd/mm/yyyy') > to_date( '${dataInicial}' , 'dd/mm/yyyy')
       and to_date(p.dt_fechamento,'dd/mm/yyyy') < to_date( '${dataFinal}' , 'dd/mm/yyyy') `;
  }

  allNDSQL(dataInicial: string, dataFinal: string, empresa: string): string {
    return `select * from PAINEL_OS p
       where p.codigofiltro in  ( ${empresa} )
       and to_date(p.dtemissaond ,'dd/mm/yyyy') > to_date( '${dataInicial}' , 'dd/mm/yyyy')
       and to_date(p.dtemissaond ,'dd/mm/yyyy') < to_date( '${dataFinal}' , 'dd/mm/yyyy')`;
  }

  labelsSQL(): string {
    const grupo = localStorage.getItem('gerencial_db').toUpperCase();
    return `SELECT column_name FROM all_tab_cols
            WHERE table_name = 'PAINEL_OS' AND OWNER = '${grupo === 'ALELO' ? environment.banco_alelo : environment.banco_tfleet }'
            ORDER BY COLUMN_ID`;
  }

  nomesEmpresasSQL(empresa): string {
    return `select apelido, codigocli, 'false' from cgclifor where codigocli in ( ${empresa} )`;
  }

  statusValoresOSSQL(dataInicial: string, dataFinal: string, empresa: string): string {
    return `select DECODE(STATUS,'ACIDENTE ','SINISTRO','MOVIMENTACAO','OUTROS','CONCESSIONÁRIA (C)','CORRETIVA',
           'ACESSORIO','OUTROS',STATUS) AS STATUS,
            sum(VALOR_OS) AS vl_total, COUNT(ORDEM_SERVICO ) AS qnt from PAINEL_OS p
            where p.codigofiltro in  ( ${empresa} )
            and to_date(p.dt_fechamento,'dd/mm/yyyy') > to_date( '${dataInicial}' , 'dd/mm/yyyy')
            and to_date(p.dt_fechamento,'dd/mm/yyyy') < to_date( '${dataFinal}' , 'dd/mm/yyyy')
            group by DECODE(STATUS,'ACIDENTE ','SINISTRO','MOVIMENTACAO','OUTROS','CONCESSIONÁRIA (C)','CORRETIVA',
            'ACESSORIO','OUTROS',STATUS)`;
  }

  statusValoresNDSQL(dataInicial: string, dataFinal: string, empresa: string): string {
    return `select DECODE(STATUS,'ACIDENTE ','SINISTRO','MOVIMENTACAO','OUTROS','CONCESSIONÁRIA (C)','CORRETIVA',
            'ACESSORIO','OUTROS',STATUS) AS STATUS,
            sum(VALOR_OS) AS vl_total, COUNT(ORDEM_SERVICO ) AS qnt from PAINEL_OS p
            where p.codigofiltro in  ( ${empresa} )
            and to_date(p.dtemissaond ,'dd/mm/yyyy') > to_date( '${dataInicial}' , 'dd/mm/yyyy')
            and to_date(p.dtemissaond ,'dd/mm/yyyy') < to_date( '${dataFinal}' , 'dd/mm/yyyy')
            group by DECODE(STATUS,'ACIDENTE ','SINISTRO','MOVIMENTACAO','OUTROS','CONCESSIONÁRIA (C)','CORRETIVA',
            'ACESSORIO','OUTROS',STATUS) `;
  }

  top10PlacaOSSQL(dataInicial: string, dataFinal: string, empresa: string): string {
    return `SELECT * FROM (
       SELECT placa, sum(VALOR_OS ) AS total FROM PAINEL_OS p
       where p.codigofiltro in  ( ${empresa})
       and to_date(p.dt_fechamento,'dd/mm/yyyy') > to_date( '${dataInicial}' , 'dd/mm/yyyy')
       and to_date(p.dt_fechamento,'dd/mm/yyyy') < to_date( '${dataFinal}' , 'dd/mm/yyyy')
       GROUP BY PLACA ORDER BY 2 DESC
       ) WHERE rownum <= 10`;
  }

  top10PlacaNDSQL(dataInicial: string, dataFinal: string, empresa: string): string {
    return `SELECT * FROM (
       SELECT placa, sum(VALOR_OS ) AS total FROM PAINEL_OS p
       where p.codigofiltro in  ( ${empresa})
       and to_date(p.dtemissaond ,'dd/mm/yyyy') > to_date( '${dataInicial}' , 'dd/mm/yyyy')
       and to_date(p.dtemissaond ,'dd/mm/yyyy') < to_date( '${dataFinal}' , 'dd/mm/yyyy')
       GROUP BY PLACA ORDER BY 2 DESC
       ) WHERE rownum <= 10`;
  }

  top10UnidadeOSSQL(dataInicial: string, dataFinal: string, empresa: string): string {
    return `SELECT * FROM (
       SELECT unidade, sum(VALOR_OS ) AS total FROM PAINEL_OS p
       where p.codigofiltro in  ( ${empresa})
       and to_date(p.dt_fechamento,'dd/mm/yyyy') > to_date( '${dataInicial}' , 'dd/mm/yyyy')
       and to_date(p.dt_fechamento,'dd/mm/yyyy') < to_date( '${dataFinal}' , 'dd/mm/yyyy')
       GROUP BY unidade ORDER BY 2 DESC
       ) WHERE rownum <= 10`;
  }

  top10UnidadeNDSQL(dataInicial: string, dataFinal: string, empresa: string): string {
    return `SELECT * FROM (
       SELECT unidade, sum(VALOR_OS ) AS total FROM PAINEL_OS p
       where p.codigofiltro in  ( ${empresa})
       and to_date(p.dtemissaond ,'dd/mm/yyyy') > to_date( '${dataInicial}' , 'dd/mm/yyyy')
       and to_date(p.dtemissaond ,'dd/mm/yyyy') < to_date( '${dataFinal}' , 'dd/mm/yyyy')
       GROUP BY unidade ORDER BY 2 DESC
       ) WHERE rownum <= 10`;
  }

  top10CorretivasOSSQL(dataInicial: string, dataFinal: string, empresa: string): string {
    return `SELECT * FROM (
      SELECT item, trunc(sum(total),2) AS total FROM top_10_corretiva_painel p
       where p.CODIGOPROPRI in  ( ${empresa})
       and p.dtfechamento > to_date( '${dataInicial}' , 'dd/mm/yyyy')
       and p.dtfechamento < to_date( '${dataFinal}' , 'dd/mm/yyyy')
       GROUP BY item ORDER BY 2 DESC
       ) WHERE rownum <= 10`;
  }

  top10CorretivasNDSQL(dataInicial: string, dataFinal: string, empresa: string): string {
    return `SELECT * FROM (
      SELECT item, trunc(sum(total),2) AS total FROM top_10_corretiva_painel p
       where p.CODIGOPROPRI in  ( ${empresa})
       and p.EMISSAO_ND > to_date( '${dataInicial}' , 'dd/mm/yyyy')
       and p.EMISSAO_ND < to_date( '${dataFinal}' , 'dd/mm/yyyy')
       GROUP BY item ORDER BY 2 DESC
       ) WHERE rownum <= 10`;
  }

  top10PreventivasOSSQL(dataInicial: string, dataFinal: string, empresa: string): string {
    return `SELECT * FROM (
      SELECT item, trunc(sum(total),2) AS total FROM top_10_preventiva_painel p
       where p.CODIGOPROPRI in  ( ${empresa})
       and p.dtfechamento > to_date( '${dataInicial}' , 'dd/mm/yyyy')
       and p.dtfechamento < to_date( '${dataFinal}' , 'dd/mm/yyyy')
       GROUP BY item ORDER BY 2 DESC
       ) WHERE rownum <= 10`;
  }

  top10PreventivasNDSQL(dataInicial: string, dataFinal: string, empresa: string): string {
    return `SELECT * FROM (
      SELECT item, trunc(sum(total),2) AS total FROM top_10_preventiva_painel p
       where p.CODIGOPROPRI in  ( ${empresa})
       and p.EMISSAO_ND > to_date( '${dataInicial}' , 'dd/mm/yyyy')
       and p.EMISSAO_ND < to_date( '${dataFinal}' , 'dd/mm/yyyy')
       GROUP BY item ORDER BY 2 DESC
       ) WHERE rownum <= 10`;
  }

  economiaOSSQL(dataInicial: string, dataFinal: string, empresa: string): string {
    return `SELECT to_char(to_date(DT_FECHAMENTO,'dd/mm/yyyy'), 'YYYY') || '/' ||
            to_char(to_date(DT_FECHAMENTO,'dd/mm/yyyy'), 'MM') AS periodo,
            trunc(sum(VALOR_SOLICITADO),2) AS solicitado,
            trunc(sum(VALOR_OS),2) AS aprovado,
            trunc(sum(VALOR_SOLICITADO) - sum(VALOR_OS),2) AS economia,
            (sum(VALOR_SOLICITADO) - sum(VALOR_OS))/sum(VALOR_SOLICITADO) AS economia_porcento
            FROM PAINEL_OS p
            where p.CODIGOFILTRO in  ( ${empresa})
            and to_date(p.dt_fechamento,'dd/mm/yyyy') > to_date( '${dataInicial}' , 'dd/mm/yyyy')
            and to_date(p.dt_fechamento,'dd/mm/yyyy') < to_date( '${dataFinal}' , 'dd/mm/yyyy')
            GROUP BY to_char(to_date(DT_FECHAMENTO,'dd/mm/yyyy'), 'YYYY') || '/' ||
                      to_char(to_date(DT_FECHAMENTO,'dd/mm/yyyy'), 'MM')
            order by 1`;
  }

  economiaNDSQL(dataInicial: string, dataFinal: string, empresa: string): string {
    return `SELECT to_char(to_date(EMISSAO_ND,'dd/mm/yyyy'), 'YYYY') || '/' ||
            to_char(to_date(EMISSAO_ND,'dd/mm/yyyy'), 'MM') AS periodo,
            trunc(sum(VALOR_SOLICITADO),2) AS solicitado,
            trunc(sum(VALOR_OS),2) AS aprovado,
            trunc(sum(VALOR_SOLICITADO) - sum(VALOR_OS),2) AS economia,
            trunc((sum(VALOR_SOLICITADO) - sum(VALOR_OS))/sum(VALOR_SOLICITADO)) AS economia_porcento
            FROM PAINEL_OS p
            where p.CODIGOFILTRO in  ( ${empresa})
            and p.EMISSAO_ND > to_date( '${dataInicial}' , 'dd/mm/yyyy')
            and p.EMISSAO_ND < to_date( '${dataFinal}' , 'dd/mm/yyyy')
            GROUP BY to_char(to_date(EMISSAO_ND,'dd/mm/yyyy'), 'YYYY') || '/' ||
                      to_char(to_date(EMISSAO_ND,'dd/mm/yyyy'), 'MM')
            order by 1`;
  }

  TCOSQL(dataInicial: string, dataFinal: string, empresa: string): string {
    return `select * from table(PAINEL_TCO( to_date('${dataInicial}','dd/mm/yyyy') ,
                                            to_date('${dataFinal}','dd/mm/yyyy') ,
                                             ${empresa} )) where veiculo is not null`;
  }

  labelsSQLR(): string {
    const grupo = localStorage.getItem('gerencial_db').toUpperCase();
    return `SELECT column_name FROM all_tab_cols
            WHERE table_name = 'PAINEL_OS' AND OWNER = '${grupo === 'ALELO' ? environment.banco_alelo : environment.banco_tfleet }'
            ORDER BY column_name`;
  }

  getCubosIC(codigofun: string): string {
    return `select * from painel_acesso_ic where codigofun = ${codigofun} order by codigocubo, ordem`;
  }

  getLabelCubosIc(codigocubo: string): string {
    return `select titulo from iccubocampo where codigocubo = ${codigocubo} order by ordem `;
  }

}
