import { Component, OnInit, OnDestroy } from '@angular/core';

import { NavigationEnd, Router } from '@angular/router';
import { ServicosService } from '../content/servicos/servicos.service';
import { FrotaService } from '../content/frota/frota.service';
import { MultasService } from '../content/infracoes/infracoes.service';
import { AESEncryptDecryptService } from '../AESEncryptDecrypt.service';

@Component({
  selector: 'pt-relatorio-rapido',
  templateUrl: './relatorio-rapido.component.html',
  styleUrls: ['./relatorio-rapido.component.css'],
})
export class RelatorioRapidoComponent implements OnInit, OnDestroy {
  opcao: number;
  opcoes: string[] = [];
  ok = true;
  lista: string[] = [];
  selecionados: string[] = [];
  loadingData = false;
  done = false;

  csvData = [];
  dados = [];

  CSVoptions = {
    fieldSeparator: ';',
    quoteStrings: '"',
    decimalseparator: ',',
    headers: [],
    showTitle: true,
    title: 'Relatório Rápido',
    useBom: true,
    removeNewLines: false,
    keys: []
  };

  mySubscription: any;

  constructor(private router: Router,
              private servicosService: ServicosService,
              private multasService: MultasService,
              private frotaService: FrotaService,
              private crypto: AESEncryptDecryptService
    ) {
    localStorage.setItem('component', 'rapido');
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
  }

  geraDados(): void{
    // tslint:disable-next-line: triple-equals
    if (this.opcao == 1){
      this.selecionados = [];
      this.frotaService.getLabelsR().subscribe(labels => {
        this.lista = labels.result;
      });
    }
    // tslint:disable-next-line: triple-equals
    if (this.opcao == 2){
      this.selecionados = [];
      this.servicosService.getLabelsR().subscribe(labels => {
        this.lista = labels.result;
      });
    }
    // tslint:disable-next-line: triple-equals
    if (this.opcao == 3){
      this.selecionados = [];
      this.multasService.getLabelsR().subscribe(labels => {
        this.lista = labels.result;
      });
    }
  }

  consultaBanco(comando: string): void {
    this.servicosService.consultaBanco(comando).subscribe(sucesso => {
      this.dados = sucesso.result;
      this.CSVdataGenerator();
    });
  }

  CSVdataGenerator(): void {
    this.csvData = [];
    this.CSVoptions.headers = this.selecionados;
    const clone = this.dados;

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < clone.length; i++){
      for (let j = 0; j < clone[i].length; j++){
        if (clone[i][j] === '' || clone[i][j] === null ||
            // tslint:disable-next-line: triple-equals
            clone[i][j].toString().length <= 0 || clone[i][j] == 0) {
          clone[i][j] = '-';
        }
      }
      this.csvData.push(clone[i]);
    }
    this.loadingData = false;
    this.done = true;

  }

  replace(texto: string): string {
    return texto.toString().replace('_', ' ');
  }

  setSelected(id: string): void {
    const found = this.selecionados.find((element) => element === id);
    const chip = document.getElementById(id);

    if (found) {
      const index = this.selecionados.indexOf(id);
      this.selecionados.splice(index, 1);
      chip.style.backgroundColor = 'white';
      chip.style.color = 'green';
    } else {
      this.selecionados.push(id);
      chip.style.backgroundColor = 'green';
      chip.style.color = 'white';
    }
  }

  zerar(): void {
    this.loadingData = false;
    this.done = false;
  }

  makeSQL(): void {
    this.loadingData = true;
    let comando = 'select ';
    for (const i of this.selecionados){
      comando += i.toString().replace(' ', '_') + ',';
    }
    comando = comando.substr(0, comando.length - 1);
    // tslint:disable-next-line: triple-equals
    if (this.opcao == 1){
      comando += ` from ga_frota_ativa where proprietario in (
                  ${this.crypto.decrypt(localStorage.getItem('empresa'))})`;
    }
    // tslint:disable-next-line: triple-equals
    if (this.opcao == 2){
      comando += ` from PAINEL_OS where codigofiltro in (
                  ${this.crypto.decrypt(localStorage.getItem('empresa'))})
                   and to_date(dt_fechamento,'dd/mm/yyyy') >= sysdate - 365`;
    }
    // tslint:disable-next-line: triple-equals
    if (this.opcao == 3){
      comando += ` from PAINEL_MULTA where codigocli in (
                  ${this.crypto.decrypt(localStorage.getItem('empresa'))})
                  and trunc(dtinfracao) >= sysdate - 365`;
    }
    this.consultaBanco(comando);
  }

}
