// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replace.s `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'http://devtfleet.tfleet.com.br:3000/api',
  banco_tfleet: 'DEVSYSTFLEET',
  banco_alelo: 'DEVSYSTFLEET',
  decryptCode: 'Seja_fiel_a_ti_mesmo_e_que_a_minha_benção_te_amadureça_em_teu_espírito'
};

/*
_* For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
