import { Component, OnInit, Inject } from '@angular/core';
import { LoginService } from 'src/app/login/login.service';
import { Login } from 'src/app/login/login.model';

@Component({
  selector: 'pt-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  token: string;
  usuarioLogado: Login = {
    codigofun: null,
    nome: null,
    empresa: null,
    grupoAcesso: null,
  };
  class: string;
  mobile: boolean;

  constructor(private loginService: LoginService) {}

  ngOnInit(): void {
    this.token = this.loginService.getToken();
    this.getUsuarioLogado();
    this.class = this.usuarioLogado.grupoAcesso.toLocaleLowerCase();
  }

  getUsuarioLogado(): void {
    this.usuarioLogado.codigofun = JSON.parse(
      atob(this.token.split('.')[1])
    ).codigofun;
    this.usuarioLogado.nome = JSON.parse(atob(this.token.split('.')[1])).nome;
    this.usuarioLogado.empresa = JSON.parse(
      atob(this.token.split('.')[1])
    ).empresa;
    this.usuarioLogado.grupoAcesso = JSON.parse(
      atob(this.token.split('.')[1])
    ).grupoAcesso;
  }
}
