import { Component, OnInit } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import { LoginService } from './login.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';

@Component({
  selector: 'pt-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  logging = false;
  number: number = 50;

  constructor(private loginService: LoginService,
              private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  emailFormControl = new FormControl('', [
    Validators.required
  ]);
  passwordFormControl = new FormControl('', [
    Validators.required
  ]);

  grupoFormControl = new FormControl('', [
    Validators.required
  ]);

  onLogin(){
    if(this.emailFormControl.invalid){
      return;
    }
    if(this.passwordFormControl.invalid){
      return;
    }
    if(this.grupoFormControl.invalid){
      return;
    }
    if(this.grupoFormControl.value.toUpperCase() === 'TFLEET' || this.grupoFormControl.value.toUpperCase() === 'ALELO'){
      this.logging = true;
      let dataInicial: Date = new Date();
      let dataFinal: Date = new Date();

      dataFinal.setMinutes(0);
      dataFinal.setHours(0);
      dataFinal.setSeconds(0);
      dataFinal.setMilliseconds(0);
      dataInicial.setDate(dataFinal.getDate() - 365);

      localStorage.setItem('dataInicial' , moment(dataInicial).format('DD/MM/YYYY'));
      localStorage.setItem('dataFinal', moment(dataFinal).format('DD/MM/YYYY'));
      localStorage.setItem('opcao', 'os');
      localStorage.setItem('gerencial_db', this.grupoFormControl.value);

      this.loginService.login(this.emailFormControl.value.toUpperCase(),
                              this.passwordFormControl.value,
                              this.grupoFormControl.value);
                              this.logging = false;
      return;
    }
    this.snackBar.open('Grupo de Acesso Inválido','', {
      duration: 3000,
      panelClass: 'error-snack-bar'
  })
   return;

  }

}
