export const loginSQL =
  `SELECT f.codigofun, f.nome, cli.cliente as empresa 
  FROM cgfuncio f 
  INNER JOIN(SELECT codigofun, LISTAGG(codigocli, ' , ') WITHIN 
  GROUP( 
  ORDER BY codigofun) AS cliente 
  FROM cgfunclie d 
  GROUP BY codigofun) cli ON cli.codigofun = f.codigofun 
  WHERE f.apelido = : apelido 
  AND f.senhaacesso = password.encrypt( : senha ) `;
