import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule, LOCALE_ID } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { AngularMaterialModule } from './angular-material.mod';
import { RouterModule } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { CountUpModule } from 'ngx-countup';
import { GoogleChartsModule } from 'angular-google-charts';
import { ParticlesDirective } from './login/particles.directive';
import { MatTableExporterModule } from 'mat-table-exporter';
import { Angular2CsvModule } from 'angular2-csv';
import { CompileModule} from 'p3x-angular-compile';

import { ROUTES } from './app.routes';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { PainelComponent } from './painel/painel.component';
import { ContentComponent } from './painel/content/content.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToolbarComponent } from './painel/toolbar/toolbar.component';
import { SidebarComponent } from './painel/sidebar/sidebar.component';
import { HomeComponent } from './painel/content/home/home.component';
import { AtendimentoComponent } from './painel/content/atendimento/atendimento.component';
import { ServicosComponent } from './painel/content/servicos/servicos.component';
import { FrotaComponent } from './painel/content/frota/frota.component';
import { CustoComponent } from './painel/content/custo/custo.component';
import { InfracoesComponent } from './painel/content/infracoes/infracoes.component';
import { FooterComponent } from './painel/footer/footer.component';
import { AuthGuard } from './login/login.guard';
import { LoginInterceptor } from './login/login.interceptor';
import { RelatorioRapidoComponent } from './painel/relatorio-rapido/relatorio-rapido.component';
import { AllServicosComponent } from './painel/content/home/allServicos/allServicos.component';
import { DetalhesHomeComponent } from './painel/content/home/detalhes/detalhesHome.component';
import { ExportCSVComponent } from './painel/content/home/allServicos/csv.component';
import { GeradorRelatorioGerencialComponent, OpcoesComponent } from './painel/content/gerador-relatorio-gerencial/gerador-relatorio-gerencial.component';
import { GraficoComponent } from './painel/content/gerador-relatorio-gerencial/grafico/grafico.component';
import { TabelaComponent } from './painel/content/gerador-relatorio-gerencial/tabela/tabela.component';
import { TituloComponent } from './painel/content/gerador-relatorio-gerencial/titulo/titulo.component';
import { DynamicIoModule, DynamicModule } from 'ng-dynamic-component';
import { IcExpressComponent } from './painel/content/ic-express/ic-express.component';

registerLocaleData(localePt, 'pt');

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PainelComponent,
    ContentComponent,
    ToolbarComponent,
    SidebarComponent,
    HomeComponent,
    AtendimentoComponent,
    ServicosComponent,
    FrotaComponent,
    CustoComponent,
    InfracoesComponent,
    FooterComponent,
    ParticlesDirective,
    RelatorioRapidoComponent,
    AllServicosComponent,
    DetalhesHomeComponent,
    ExportCSVComponent,
    GeradorRelatorioGerencialComponent,
    OpcoesComponent,
    GraficoComponent,
    TabelaComponent,
    TituloComponent,
    IcExpressComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    RouterModule.forRoot(ROUTES),
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    CountUpModule,
    HttpClientModule,
    GoogleChartsModule.forRoot(),
    MatTableExporterModule,
    Angular2CsvModule,
    DynamicIoModule,
    DynamicModule,
    CompileModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt' },
    DatePipe,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: LoginInterceptor, multi: true },
    GeradorRelatorioGerencialComponent,
    OpcoesComponent,
    TituloComponent,
  ],
  entryComponents: [
    DetalhesHomeComponent,
    AllServicosComponent,
    SidebarComponent
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
