import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/login/login.service';
import { Login } from 'src/app/login/login.model';
import { MatDialog } from '@angular/material/dialog';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { ServicosService } from '../content/servicos/servicos.service';
import { FormControl } from '@angular/forms';
import { AESEncryptDecryptService } from '../AESEncryptDecrypt.service';
import { Router } from '@angular/router';

@Component({
  selector: 'pt-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css'],
})
export class ToolbarComponent implements OnInit {
  mobile: boolean;
  timer: boolean;
  token: string;
  usuarioLogado: Login = {
    codigofun: null,
    nome: null,
    empresa: null,
    grupoAcesso: null,
  };
  primeiroNome: string;
  class: string;
  listaEmpresa = [];
  nomeEmpresa: string[] = [];
  codigoEmpresa: number[] = [];
  numeroZero = 1;
  nodata = false;
  word: string;

  empresasForm = new FormControl();

  showMenu: any;

  constructor(
    private loginService: LoginService,
    private dialog: MatDialog,
    private servicosService: ServicosService,
    private crypto: AESEncryptDecryptService,
    private router: Router) { }

  ngOnInit(): void {
    this.timer = true;
    this.token = this.loginService.getToken();
    this.getUsuarioLogado();
    this.servicosService.getApelidos(this.usuarioLogado.empresa).subscribe(sucesso => {
      this.listaEmpresa.push(['Todas', 1]);
      for (const empresa of sucesso.result) {
        this.listaEmpresa.push(empresa);
      }
      if (localStorage.getItem('reload') === 'false') {
        this.separaEmpresas();
        this.empresasForm.setValue([this.codigoEmpresa[1]]);
        localStorage.setItem('empresa', this.crypto.encrypt(this.codigoEmpresa[1].toString()));
        localStorage.setItem('empresaN', this.crypto.encrypt(this.nomeEmpresa[1].toString()));
        localStorage.setItem('reload', 'true');
      } else {
        this.separaEmpresas();
        let valores = this.crypto.decrypt(localStorage.getItem('empresa'));
        valores = valores.split(',');
        valores = this.stringToNumberArray(valores);
        this.empresasForm.setValue(valores);
      }

    });
    this.class = this.usuarioLogado.grupoAcesso.toLocaleLowerCase();
    if (window.screen.width <= 768) {
      // 768px portrait
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  logOff(): void {
    this.loginService.logout();
  }

  separaEmpresas(): void {
    for (const empresa of this.listaEmpresa) {
      this.nomeEmpresa.push(empresa[0]);
      this.codigoEmpresa.push(empresa[1]);
    }
  }

  getUsuarioLogado(): void {
    this.usuarioLogado.codigofun = JSON.parse(
      atob(this.token.split('.')[1])
    ).codigofun;
    this.usuarioLogado.nome = JSON.parse(atob(this.token.split('.')[1])).nome;
    this.usuarioLogado.empresa = JSON.parse(
      atob(this.token.split('.')[1])
    ).empresa;
    this.usuarioLogado.grupoAcesso = JSON.parse(
      atob(this.token.split('.')[1])
    ).grupoAcesso;
    this.primeiroNome =
      this.usuarioLogado.nome.split(' ')[0].charAt(0).toUpperCase() +
      this.usuarioLogado.nome.split(' ')[0].toLowerCase().slice(1);
    this.word = btoa(`${this.usuarioLogado.codigofun}:GASFKUHASF73243HSDF67`);
  }

  openMenu(): void {
    this.showMenu = this.dialog.open(SidebarComponent, {
      width: '60vw',
      data: {
        mobile: this.mobile
      }
    });
  }

  stringToNumberArray(array: string[]): number[] {
    const numbers: number[] = [];
    for (const item of array) {
      numbers.push(this.stringToNumber(item));
    }
    return numbers;
  }

  stringToNumber(nome: string): number {
    return Number(nome);
  }

  retornaNome(codigo: number): string {
    if (codigo) {
      const dado = this.listaEmpresa.filter(dados => Number(dados[1]) === Number(codigo));
      this.nodata = false;
      return dado[0][0];
    }
    this.nodata = true;
    return 'Nenhuma empresa selecionada.';
  }

  retornaNomes(codigos: number[]): string[] {
    const nomes: string[] = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < codigos.length; i++) {
      const dado = this.listaEmpresa.filter(empresa => empresa[1] === codigos[i]);
      nomes.push(dado[0][0]);
    }
    return nomes;
  }

  mudaEmpresa(): any {
    if (!this.nodata) {
      localStorage.setItem('reload', 'true');
      const codigocli = this.empresasForm.value;
      const nomes = this.retornaNomes(this.empresasForm.value);

      localStorage.setItem('empresa', this.crypto.encrypt(codigocli.toString()));
      localStorage.setItem('empresaN', this.crypto.encrypt(nomes.toString()));
      switch (localStorage.getItem('component')) {
        case 'home':
          this.router.navigate(['/painel/home']);
          break;
        case 'atendimento':
          this.router.navigate(['/painel/atendimento']);
          break;
        case 'servicos':
          this.router.navigate(['/painel/servicos']);
          break;
        case 'frota':
          this.router.navigate(['/painel/frota']);
          break;
        case 'infracoes':
          this.router.navigate(['/painel/infracoes']);
          break;
        case 'custo':
          this.router.navigate(['/painel/custo']);
          break;
        case 'rapido':
          this.router.navigate(['/painel/rapido']);
          break;
        default:
          this.router.navigate(['/painel/home']);
          break;
      }
    }
  }

  selecionarTudo(): void {
    if (this.codigoEmpresa.length === this.empresasForm.value.length + 1) {
      this.empresasForm.setValue([this.codigoEmpresa[1]]);
    } else {
      this.empresasForm.setValue(this.codigoEmpresa);
    }
  }

  deselectAll(): void {
    const valores: [] = this.empresasForm.value;
    const filtro = valores.filter(dado => dado !== 0);
    this.empresasForm.setValue(filtro);
  }

  toSC(): void {
    const link = `https://sistemasp.tfleet.com.br/ServiceCenter/loginExterno.jsf?codigoUsuario=${this.word}&grupoAcesso=${this.usuarioLogado.grupoAcesso.toLowerCase()}`;
    window.open(link, '_blank');
  }

  toIC(): void {
    const link = `https://sistemasp.tfleet.com.br/IntelligenceCenter/loginExterno.jsf?codigoUsuario=${this.word}&grupoAcesso=${this.usuarioLogado.grupoAcesso.toLowerCase()}`;
    window.open(link, '_blank');
  }

  toWeb(): void {
    const link = `https://sistemasp.tfleet.com.br/siscaptura/loginExterno.jsp?grupoAcesso=${this.usuarioLogado.grupoAcesso.toLowerCase()}&codigoUsuario=${this.word}`;
    window.open(link, '_blank');
  }


}
