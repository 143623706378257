import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'pt-atendimento',
  templateUrl: './atendimento.component.html',
  styleUrls: ['./atendimento.component.css']
})
export class AtendimentoComponent implements OnInit, OnDestroy {

  //atendimento charts
  atendimentoChartsData;
  atendimentoChartType;
  atendimentoChartOptions;

  //pie charts
  pieChartsData;
  pieChartType;
  pieChartOptions;

  //pie charts
  donutChartsData;
  donutChartType;
  donutChartOptions;

  //combo charts
  comboChartsData;
  comboChartType;
  comboChartOptions;

  //Map charts
  mapChartsData;
  mapChartType;
  mapChartOptions;

  mySubscription: any;

  constructor(private router: Router) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });
  }

  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.generateCharts();
  }

  generateCharts() {
    this.generateAtendimentoChart();
    this.generatePieChart();
    this.generateDonutChart();
    this.generateComboChart();
    this.generateMapChart();
  }

  generateAtendimentoChart() {
    this.atendimentoChartsData = [
      ['Aguardando Aprovação', 600],
      ['Agendado sem Orçamento', 252],
      ['Pendente negociação', 313],
      ['Aguardando aprovação Cliente', 102]
    ]
    this.atendimentoChartType = 'ColumnChart';
    this.atendimentoChartOptions = {
      animation: {
        duration: 1000,
        easing: 'out',
        startup: true
      },
      legend: { position: "none" },
      backgroundColor: 'transparent',
      position: 'center',
      theme: 'material',
      colors: [this.randomColorGenerator()]
    }
  }

  generatePieChart() {
    this.pieChartsData = [
      ['COCA', 321],
      ['VIVO', 123],
      ['PMI', 852],
      ['GSK', 258],
      ['JTI', 258]
    ]
    this.pieChartType = 'PieChart';
    this.pieChartOptions = {
      is3D: true,
      backgroundColor: 'transparent',
      position: 'center',
      theme: 'material'
    }
  }

  generateDonutChart() {
    this.donutChartsData = [
      ['Concluido', 321],
      ['Em atendimento', 123],
      ['Pendente Aprovação Cliente', 852],
      ['Pendente Aprovação Tfleet', 258]
    ]
    this.donutChartType = 'PieChart';
    this.donutChartOptions = {
      pieHole: 0.4
    }
  }

  generateComboChart() {
    this.comboChartsData = [
      ['Corretiva', 321, 630],
      ['Preventiva', 123, 564],
      ['Sinistro', 852, 998],
      ['Outros', 258, 1239]
    ]
    this.comboChartType = 'ComboChart';
    this.comboChartOptions = {
      hAxis: {
        title: 'Motivo'
      },
      seriesType: 'bars',
      series: { 1: { type: 'line' } }
    }
  }

  generateMapChart() {
    this.mapChartsData = [
      ['São Paulo', 321],
      ['São Bernardo do Campo', 123],
      ['São Caetano', 852],
      ['Santo André', 258]
    ]
    this.mapChartType = 'Map';
    this.mapChartOptions = {
      showTip: true
    }
  }

  randomColorGenerator() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
}
