import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pt-painel',
  templateUrl: './painel.component.html',
  styleUrls: ['./painel.component.css']
})
export class PainelComponent implements OnInit {

  mobile: boolean;

  constructor() { }

  ngOnInit(): void {

    if (window.screen.width <= 768) { // 768px portrait
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

}
