import { AESEncryptDecryptService } from './../../AESEncryptDecrypt.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoginService } from 'src/app/login/login.service';

import { Login } from 'src/app/login/login.model';
import { AtendimentoService } from '../atendimento/atendimento.service';
import { ServicosService } from '../servicos/servicos.service';
import { FrotaService } from '../frota/frota.service';
import { DetalhesHomeComponent } from './detalhes/detalhesHome.component';
import { Resumo } from '../atendimento/resumo.model';
import { Marca } from '../frota/frota.model';
import { Status } from '../atendimento/status.model';
import { Tools } from '../../tools';
import { ChartModel } from '../../charts.model';
import { NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MultasService } from '../infracoes/infracoes.service';

@Component({
  selector: 'pt-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, OnDestroy {
  filter: any;
  ok = false;

  token: string;

  resumoAtendimento: Resumo = { abertos: null, fechados: null, total: null };
  resumoServicos: Resumo = { abertos: null, fechados: null, total: null };

  statusAtendimento: Status[] = [];
  statusServico: Status[] = [];
  statusMulta: Status[] = [];
  totalMulta = 0;

  marcas: Marca[] = [];
  notFound = 'Sem dados para exibir';

  // atendimento charts
  atendimentoChart: ChartModel = { type: null, data: null, options: null };
  // servicos charts
  servicosChart: ChartModel = { type: null, data: null, options: null };
  // servicos charts
  multaChart: ChartModel = { type: null, data: null, options: null };
  // servicos charts
  frotaChart: ChartModel = { type: null, data: null, options: null };

  frotaAtiva = 0;
  progress = 0;
  full: boolean;

  usuarioLogado: Login = {
    codigofun: null,
    nome: null,
    empresa: null,
    grupoAcesso: null,
  };

  myOpts = {
    separator: '.',
  };

  mySubscription: any;

  constructor(
    private loginService: LoginService,
    private atendimentoService: AtendimentoService,
    private servicosService: ServicosService,
    private frotaService: FrotaService,
    private tools: Tools,
    private router: Router,
    private dialog: MatDialog,
    private multasService: MultasService,
    private crypto: AESEncryptDecryptService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  //  Executa ao iniciar
  ngOnInit(): void {
    localStorage.setItem('component', 'home');
    this.getToken();
    this.getUsuarioLogado();
    setTimeout(() => {
      this.setResumo(1);
    }, 1000);
  }

  // Gera os gráficos
  generateCharts(): void {
    this.totalMulta = 0;
    for (const status of this.statusMulta) {
      this.totalMulta += status[1];
    }
    this.generateAtendimentoChart();
    this.generateServicoChart();
    this.generateMultasChart();
    this.generateFrotaChart();
  }

  // Pega o token que contém os dados do usuário
  getToken(): void {
    this.token = this.loginService.getToken();
  }

  // Preenche os dados do usuário logado pelo token
  getUsuarioLogado(): void {
    this.usuarioLogado.codigofun = JSON.parse(
      atob(this.token.split('.')[1])
    ).codigofun;
    this.usuarioLogado.nome = JSON.parse(atob(this.token.split('.')[1])).nome;
    this.usuarioLogado.empresa = JSON.parse(
      atob(this.token.split('.')[1])
    ).empresa;
    this.usuarioLogado.grupoAcesso = JSON.parse(
      atob(this.token.split('.')[1])
    ).grupoAcesso;
  }

  // Pega os dados para preencher os cards
  setResumo(anos: number): void {
    // atendimento resumo
    this.atendimentoService
      .generateResumo(anos * 365, this.crypto.decrypt(localStorage.getItem('empresa'))).subscribe(resumoAtendimento => {
          this.resumoAtendimento = resumoAtendimento.result;
          this.progress = 14;
          // Resumo OS
          this.servicosService.generateResumo(anos * 365).subscribe(resumoServicos => {
                this.resumoServicos = resumoServicos.result;
                this.progress = 28;
                // status OS
                this.servicosService.generateStatus(anos * 365).subscribe(statusServico => {
                      this.statusServico = statusServico.result;
                      this.progress = 56;
                      // Marcas - frota
                      this.frotaService.generateMarca().subscribe(marcas => {
                            this.marcas = marcas.result;
                            this.progress = 78;
                            // status atendimento
                            this.atendimentoService.generateStatus(anos * 365, this.crypto.decrypt(localStorage.getItem('empresa')))
                            .subscribe(statusAtendimento => {
                                  this.statusAtendimento = statusAtendimento.result;
                                  this.progress = 90;
                                  // multa gravidade
                                  this.multasService.getResumoGravidade(anos * 365).subscribe(statusMulta => {
                                    this.statusMulta = statusMulta.result;
                                    this.progress = 95;
                                    this.ok = true;
                                    this.generateCharts();
                                  }, erro => {
                                    console.log('Erro ao obter multas');
                                    console.log(erro);
                                  });
                                },
                                (erro) => {
                                  console.log('Erro ao obter status');
                                  console.log(erro);
                                }
                              );
                          },
                          (erro) => {
                            console.log('Erro ao obter dados da Frota');
                            console.log(erro);
                          }
                        );
                    },
                    (erro) => {
                      console.log('Erro ao obter dados de Status OS');
                      console.log(erro);
                    }
                  );
              },
              (erro) => {
                console.log('Erro ao obter dados de Resumo OS');
                console.log(erro);
              }
            );
        },
        (erro) => {
          console.log('Erro ao obter dados de Resumo Atendimentos');
          console.log(erro);
        }
      );
  }

  // Gera o Gráfico de Status do atendimento
  generateAtendimentoChart(): void {
    const status: string[] = ['Status'];
    const total = ['Total'];
    let statusAtual: string;
    for (const statusAt of this.statusAtendimento) {
      statusAtual = statusAt[0];
      statusAtual = statusAtual.substr(3, statusAtual.length);
      statusAtual = statusAtual.toLocaleLowerCase();
      statusAtual = statusAtual.charAt(0).toUpperCase() + statusAtual.slice(1);
      status.push(statusAtual);
      total.push(statusAt[1]);
    }

    this.atendimentoChart.names = status;
    this.atendimentoChart.data = [total];
    this.atendimentoChart.type = 'ColumnChart';
    this.atendimentoChart.options = {
      animation: {
        duration: 1000,
        easing: 'out',
        startup: true,
      },
      legend: { position: 'top', alignment: 'center', maxLines: 2 },
      backgroundColor: 'transparent',
      position: 'center',
    };
  }

  // Gera o Gráfico de Status das OSs
  generateServicoChart(): void {
    const status: string[] = ['Status'];
    const total = ['Total'];
    let statusAtual: string;
    for (const statusS of this.statusServico) {
      statusAtual = statusS[0];
      statusAtual = statusAtual.toLocaleLowerCase();
      statusAtual = statusAtual.split('_').join(' ');
      statusAtual = statusAtual.charAt(0).toUpperCase() + statusAtual.slice(1);
      status.push(statusAtual);
      total.push(statusS[1]);
    }
    this.servicosChart.names = status;
    this.servicosChart.data = [total];
    this.servicosChart.type = 'ColumnChart';
    this.servicosChart.options = {
      animation: {
        duration: 1000,
        easing: 'out',
        startup: true,
      },
      legend: { position: 'top', alignment: 'center', maxLines: 2 },
      backgroundColor: 'transparent',
      position: 'center',
    };
  }

  // gera gráfico de multas
  generateMultasChart(): void {
    this.multaChart.data = this.statusMulta;
    this.multaChart.type = 'ColumnChart';
    this.multaChart.options = {
      animation: {
        duration: 1000,
        easing: 'out',
        startup: true,
      },
      legend: { position: 'none' },
      backgroundColor: 'transparent',
      position: 'center',
      colors: [this.tools.randomColorGenerator()]
    };
  }

  // Gera o Gráfico de Frota Ativa
  generateFrotaChart(): void {

    for (const marca of this.marcas) {
      this.frotaAtiva += marca[1];
    }
    this.frotaChart.type = 'ColumnChart';
    this.frotaChart.data = this.marcas;
    this.frotaChart.options = {
      animation: {
        duration: 1000,
        easing: 'out',
        startup: true,
      },
      chartArea: { width: '90%' },
      hAxis: {
        textStyle: {
          fontSize: 10,
        },
      },
      legend: { position: 'none' },
      backgroundColor: 'transparent',
      position: 'center',
      colors: [this.tools.randomColorGenerator()]
    };
  }

  abrirDetalhes(dado: string): void {
    let data: any;
    let names: string[] = [];

    switch (dado) {
      case 'atendimento':
        data = this.statusAtendimento;
        names = ['Status', 'Quantidade'];
        break;
      case 'os':
        data = this.statusServico;
        names = ['Status', 'Quantidade'];
        break;
      case 'multas':
        data = this.statusMulta;
        names = ['Gravidade', 'Quantidade'];
        break;
      case 'frota':
        data = this.marcas;
        names = ['Marca', 'Quantidade'];
        break;
    }

    this.dialog.open(DetalhesHomeComponent, {
      width: 'wrap',
      height: 'wrap',
      panelClass: 'dialog-transparent',
      data: {
        thisData: data,
        thisNames: names,
      },
    });
  }

  isFullScreen(): boolean {
    return (
      (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.fullscreenElement && document.fullscreenElement !== null)
    );
  }

  enterFS(id: string): void {
    const page = document.getElementById(id);
    if (page.requestFullscreen) {
      page.requestFullscreen();
    } else if (page.requestFullscreen) {
      page.requestFullscreen();
    }
  }

  exitFS(): Promise<void> {
    if (document.exitFullscreen) {
      return document.exitFullscreen();
    } else if (document.exitFullscreen) {
      return document.exitFullscreen();
    }
  }

  toggleFS(id): void {
    if (!this.isFullScreen()) {
      this.full = true;
      this.enterFS(id);
    } else {
      this.full = false;
      this.exitFS();
    }
  }
}
