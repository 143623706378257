import { ServicosService } from './../../servicos/servicos.service';
import { Tools } from './../../../tools';
import { GeradorService } from './../gerador.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { FrotaService } from './../../frota/frota.service';
import { ChartModel } from './../../../charts.model';
import { Component, OnInit, Input } from '@angular/core';
import { DetalhesHomeComponent } from '../../home/detalhes/detalhesHome.component';

@Component({
  selector: 'pt-grafico',
  templateUrl: './grafico.component.html',
  styleUrls: ['./grafico.component.css']
})
export class GraficoComponent implements OnInit {

  loadingFiltro = true;
  @Input() titulo: string;
  @Input() width: string;
  @Input() id: number;
  idf: any;

  token: string;
  codigofun: string;

  grafico: ChartModel = { data: null, type: null, options: null };

  constructor(private frotaservice: FrotaService,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private geradorService: GeradorService,
              private servicosService: ServicosService,
              private tools: Tools) { }

  ngOnInit(): void {
    console.log(this.titulo);
    console.log(this.id);

    this.setGrafico();
  }

  showAlert(): void {
    this.snackBar.open('Deseja excluir esse item?', 'Sim', {
      duration: 2000,
    });
    this.snackBar._openedSnackBarRef.onAction().subscribe( () => {
      this.geradorService.pullRelatorios(this.id, Number(this.codigofun));
    });
  }

  setGrafico(): void {
    switch (this.titulo) {
      case 'Situação dos Veículos':
        this.frotaservice.generateSituacao().subscribe(resultado => {
          this.grafico.data = resultado.result;
          this.loadingFiltro = false;
        }, erro => {
          console.log('erro ao obter dados!');
          console.log(erro);
        });
        this.grafico.type = 'PieChart';
        this.grafico.options = {
          pieHole: 0.4,
          chartArea: { height: '90%' },
          legend: { position: 'right', fontsize: '1rem' },
          backgroundColor: 'transparent',
          position: 'center',
        };
        break;
      case 'Qnt Veículos/ Proprietário Legal':
        this.frotaservice.generateProprietario().subscribe(resultado => {
          this.grafico.data = resultado.result;
          this.loadingFiltro = false;
        }, erro => {
          console.log('erro ao obter dados!');
          console.log(erro);
        });
        this.grafico.type = 'ColumnChart';
        this.grafico.options = {
          animation: {
            duration: 1000,
            easing: 'out',
            startup: true,
          },
          chartArea: { width: '90%' },
          vAxis: {
            textStyle: {
              fontSize: 10,
            },
          },
          legend: { position: 'none' },
          backgroundColor: 'transparent',
          position: 'center',
          colors: [this.tools.randomColorGenerator()]
        };
        break;
      case 'Qnt Veículos/ Marca':
        this.frotaservice.generateMarca().subscribe(resultado => {
          this.grafico.data = resultado.result;
          this.loadingFiltro = false;
        }, erro => {
          console.log('erro ao obter dados!');
          console.log(erro);
        });
        this.grafico.type = 'BarChart';
        this.grafico.options = {
          animation: {
            duration: 1000,
            easing: 'out',
            startup: true,
          },
          annotations: {
            alwaysOutside: true,
          },
          chartArea: { height: '90%' },
          vAxis: {
            textStyle: {
              fontSize: 10,
            },
          },
          legend: { position: 'none' },
          backgroundColor: 'transparent',
          position: 'center',
          colors: [this.tools.randomColorGenerator()]
        };
        break;
      case 'Qnt Veículos/ Ano Modelo':
        this.frotaservice.generateAno().subscribe(resultado => {
          this.grafico.data = resultado.result;
          this.loadingFiltro = false;
        }, erro => {
          console.log('erro ao obter dados!');
          console.log(erro);
        });
        this.grafico.type = 'ColumnChart';
        this.grafico.options = {
          animation: {
            duration: 1000,
            easing: 'out',
            startup: true,
          },
          chartArea: { width: '90%' },
          vAxis: {
            textStyle: {
              fontSize: 10,
            },
          },
          legend: { position: 'none' },
          backgroundColor: 'transparent',
          position: 'center',
          colors: [this.tools.randomColorGenerator()]
        };
        break;
      case 'Qnt Veículos/ Grupo Gerencial':
        this.frotaservice.generateGrupo().subscribe(resultado => {
          this.grafico.data = resultado.result;
          this.loadingFiltro = false;
        }, erro => {
          console.log('erro ao obter dados!');
          console.log(erro);
        });
        this.grafico.type = 'BarChart';
        this.grafico.options = {
          animation: {
            duration: 1000,
            easing: 'out',
            startup: true,
          },
          chartArea: { height: '90%', width: '30%' },
          vAxis: {
            textStyle: {
              fontSize: 10,
            },
          },
          legend: { position: 'none' },
          backgroundColor: 'transparent',
          position: 'center',
          colors: [this.tools.randomColorGenerator()]
        };
        break;
      case 'Qnt Veículos/ Unidade':
        this.frotaservice.generateUnidade().subscribe(resultado => {
          this.grafico.data = resultado.result;
          this.loadingFiltro = false;
        }, erro => {
          console.log('erro ao obter dados!');
          console.log(erro);
        });
        this.grafico.type = 'BarChart';
        this.grafico.options = {
          animation: {
            duration: 1000,
            easing: 'out',
            startup: true,
          },
          chartArea: { height: '90%', width: '30%' },
          vAxis: {
            textStyle: {
              fontSize: 10,
            },
          },
          legend: { position: 'none' },
          backgroundColor: 'transparent',
          position: 'center',
          colors: [this.tools.randomColorGenerator()]
        };
        break;
      case 'Qnt Veículos/ Departamento':
        this.frotaservice.generatDepartamento().subscribe(resultado => {
          this.grafico.data = resultado.result;
          this.loadingFiltro = false;
        }, erro => {
          console.log('erro ao obter dados!');
          console.log(erro);
        });
        this.grafico.type = 'BarChart';
        this.grafico.options = {
          animation: {
            duration: 1000,
            easing: 'out',
            startup: true,
          },
          chartArea: { height: '90%', width: '30%' },
          vAxis: {
            textStyle: {
              fontSize: 10,
            },
          },
          legend: { position: 'none' },
          backgroundColor: 'transparent',
          position: 'center',
          colors: [this.tools.randomColorGenerator()]
        };
        break;
      case 'Top 10 placas':
        this.servicosService.generateTop10Placa().subscribe(resultado => {
          this.grafico.data = resultado.result;
          this.loadingFiltro = false;
        }, erro => {
          console.log('erro ao obter dados!');
          console.log(erro);
        });
        this.grafico.type = 'BarChart';
        this.grafico.options = {
          animation: {
            duration: 1000,
            easing: 'out',
            startup: true,
          },
          chartArea: { height: '90%' },
          hAxis: {
            textStyle: {
              fontSize: 8,
            },
          },
          legend: { position: 'none' },
          backgroundColor: 'transparent',
          position: 'center',
          colors: [this.tools.randomColorGenerator()]
        };
        break;
      case 'Qnt OS/ Status':
        this.servicosService.generateStatus(365).subscribe(resultado => {
          this.grafico.data = resultado.result;
          this.loadingFiltro = false;
        }, erro => {
          console.log('erro ao obter dados!');
          console.log(erro);
        });
        this.grafico.type = 'PieChart';
        this.grafico.options = {
          pieHole: 0.4,
          chartArea: { width: '90%' },
          legend: {
            position: 'right',
            maxLines: 2
          },
          backgroundColor: 'transparent',
          position: 'left',
        };
        break;
      case 'Qnt OS/ Grupo Gerencial':
        this.servicosService.generateGerencial().subscribe(resultado => {
          this.grafico.data = resultado.result;
          this.loadingFiltro = false;
        }, erro => {
          console.log('erro ao obter dados!');
          console.log(erro);
        });
        this.grafico.type = 'BarChart';
        this.grafico.options = {
          animation: {
            duration: 1000,
            easing: 'out',
            startup: true,
          },
          chartArea: { height: '90%' },
          hAxis: {
            textStyle: {
              fontSize: 8,
            },
          },
          legend: { position: 'none' },
          backgroundColor: 'transparent',
          position: 'center',
          colors: [this.tools.randomColorGenerator()]
        };
        break;
      case 'Qnt OS/ Motivo':
        this.servicosService.generateMotivo().subscribe(resultado => {
          this.grafico.data = resultado.result;
          this.loadingFiltro = false;
        }, erro => {
          console.log('erro ao obter dados!');
          console.log(erro);
        });
        this.grafico.type = 'ColumnChart';
        this.grafico.options = {
          animation: {
            duration: 1000,
            easing: 'out',
            startup: true,
          },
          chartArea: { width: '90%' },
          hAxis: {
            textStyle: {
              fontSize: 8,
            },
          },
          legend: { position: 'none' },
          backgroundColor: 'transparent',
          position: 'center',
          colors: [this.tools.randomColorGenerator()]
        };
        break;
      case 'Qnt OS/ Estado':
        this.servicosService.generatEstados().subscribe(resultado => {
          this.grafico.data = resultado.result;
          this.loadingFiltro = false;
        }, erro => {
          console.log('erro ao obter dados!');
          console.log(erro);
        });
        this.grafico.type = 'GeoChart';
        this.grafico.options = {
          region: 'BR',
          resolution: 'provinces',
          chartArea: { width: '70%' },
        };
        break;
      case 'Percentual Preventiva':
        this.servicosService.generateStaOS().subscribe(resultado => {
          this.grafico.data = resultado.result;
          this.loadingFiltro = false;
        }, erro => {
          console.log('erro ao obter dados!');
          console.log(erro);
        });
        this.generatePorcentagemChart();
        break;
      case 'IDF':

        this.grafico.type = 'Gauge';
        this.grafico.data = [['', { v: this.idf, f: this.idf + '%' }]];
        this.grafico.options = {
          greenFrom: 95,
          greenTo: 100,
          redFrom: 0,
          redTo: 80,
          yellowFrom: 80,
          yellowTo: 95,
          minorTicks: 5,
          position: 'center',
        };
        break;
      case 'Gasto/ Status':

        break;
      case 'Top 10 Unidades':

        break;
      case 'Top 10 Itens Corretivos':

        break;
      case 'Top 10 Itens Preventivo':

        break;
      case 'Tipos de Infração':

        break;
      case 'Gravidade':

        break;
      case 'Descrição Multas':

        break;
      case 'Top 20 Infratores':

        break;
      case 'Multas/ Estado':

        break;
      case 'Gasto por tipo':

        break;
      case 'Top 10 Veículos':

        break;
      case 'Top 10 Multas':

        break;

    }
  }

  isFullScreen(): boolean {
    return (
      (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.fullscreenElement && document.fullscreenElement !== null)
    );
  }

  enterFS(id: string): void {
    const page = document.getElementById(id);
    if (page.requestFullscreen) {
      page.requestFullscreen();
    } else if (page.requestFullscreen) {
      page.requestFullscreen();
    }
  }

  exitFS(): Promise<void> {
    if (document.exitFullscreen) {
      return document.exitFullscreen();
    } else if (document.exitFullscreen) {
      return document.exitFullscreen();
    }
  }

  toggleFS(id): void {
    if (!this.isFullScreen()) {
      this.enterFS(id);
    } else {
      this.exitFS();
    }
  }

  abrirDetalhes(dado: string): void {
    let data: any;
    let names: string[] = [];

    switch (dado) {
      case 'situacao':
        data = this.grafico.data;
        names = ['Status', 'Quantidade'];
        break;
    }

    this.dialog.open(DetalhesHomeComponent, {
      width: 'wrap',
      height: 'wrap',
      panelClass: 'dialog-transparent',
      data: {
        thisData: data,
        thisNames: names,
      },
    });
  }

  generatePorcentagemChart(): void {
    let porcentagem = 0;
    let preventiva = 0;
    let soma = 0;

    if (this.grafico.data.filter((motivo) => motivo[0] === 'PREVENTIVA')){
      const valor = this.grafico.data.filter((motivo) => motivo[0] === 'PREVENTIVA');
      preventiva = valor[0][1];
      soma += valor[0][1];
    }
    if (this.grafico.data.filter((motivo) => motivo[0] === 'CORRETIVA')){
      const valor = this.grafico.data.filter((motivo) => motivo[0] === 'CORRETIVA');
      soma += valor[0][1];
    }
    if (this.grafico.data.filter((motivo) => motivo[0] === 'ACIDENTE ')){
      const valor = this.grafico.data.filter((motivo) => motivo[0] === 'ACIDENTE ');
      soma += valor[0][1];
    }

    preventiva <= 0 ? preventiva = 0 : preventiva = preventiva;
    soma <= 0 ? porcentagem = 100 : porcentagem = Math.trunc(preventiva / soma * 100);

    this.grafico.data = [
        ['', { v: porcentagem, f: porcentagem + '%' }],
    ];
    this.grafico.type = 'Gauge';
    this.grafico.options = {
      greenFrom: 50,
      greenTo: 100,
      redFrom: 0,
      redTo: 20,
      yellowFrom: 20,
      yellowTo: 50,
      minorTicks: 5,
      position: 'center',
    };
  }

}
