import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AESEncryptDecryptService } from '../../AESEncryptDecrypt.service';

import { Resumo } from '../atendimento/resumo.model';
import { Status } from '../atendimento/status.model';
import { TopDez, StatusValor } from './servicos.model';

import { ServicoSQL } from './servicos.sql';
import { Observable } from 'rxjs';

const BACKEND_URL = environment.apiUrl + '/consulta';

@Injectable({ providedIn: 'root' })
export class ServicosService {

  empresa;
  dataInicial;
  dataFinal;
  opcao;

  database = localStorage.getItem('gerencial_db');

  constructor(private http: HttpClient,
              private sqlList: ServicoSQL,
              private crypto: AESEncryptDecryptService) { }

  generateResumo(dias: number): any {
    const sql = this.sqlList.resumoSQL(dias, this.crypto.decrypt(localStorage.getItem('empresa')));
    return this.http.post<{ result: Resumo }>(BACKEND_URL + '/label', {
      sql, db: this.database
    });
  }

  generateStatus(dias: number): any {
    const sql = this.sqlList.statusSQL(dias, this.crypto.decrypt(localStorage.getItem('empresa')));
    return this.http.post<{ result: Status[] }>(BACKEND_URL + '/consulta', {
      sql, db: this.database
    });
  }

  generateStaOS(): any {
    const sql =
      localStorage.getItem('opcao') === 'os'
        ? this.sqlList.statusOSSQL(localStorage.getItem('dataInicial'),
          localStorage.getItem('dataFinal'),
          this.crypto.decrypt(localStorage.getItem('empresa')))
        : this.sqlList.statusNDSQL(localStorage.getItem('dataInicial'),
          localStorage.getItem('dataFinal'),
          this.crypto.decrypt(localStorage.getItem('empresa')));
    return this.http.post<{ result: Status[] }>(BACKEND_URL + '/consulta', {
      sql, db: this.database
    });
  }

  generateMotivo(): any {
    const sql =
      localStorage.getItem('opcao') === 'os'
        ? this.sqlList.motivoOSSQL(localStorage.getItem('dataInicial'),
          localStorage.getItem('dataFinal'),
          this.crypto.decrypt(localStorage.getItem('empresa')))
        : this.sqlList.motivoNDSQL(localStorage.getItem('dataInicial'),
          localStorage.getItem('dataFinal'),
          this.crypto.decrypt(localStorage.getItem('empresa')));
    return this.http.post<{ result: Status[] }>(BACKEND_URL + '/consulta', {
      sql, db: this.database
    });
  }

  generateGerencial(): any {
    const sql =
      localStorage.getItem('opcao') === 'os'
        ? this.sqlList.gerencialOSSQL(localStorage.getItem('dataInicial'),
          localStorage.getItem('dataFinal'),
          this.crypto.decrypt(localStorage.getItem('empresa')))
        : this.sqlList.gerencialNDSQL(localStorage.getItem('dataInicial'),
          localStorage.getItem('dataFinal'),
          this.crypto.decrypt(localStorage.getItem('empresa')));
    return this.http.post<{ result: Status[] }>(BACKEND_URL + '/consulta', {
      sql, db: this.database
    });
  }

  generateIDF(): any {
    const sql =
      localStorage.getItem('opcao') === 'os'
        ? this.sqlList.IDFOSSQL(localStorage.getItem('dataInicial'),
          localStorage.getItem('dataFinal'),
          this.crypto.decrypt(localStorage.getItem('empresa')))
        : this.sqlList.IDFNDSQL(localStorage.getItem('dataInicial'),
          localStorage.getItem('dataFinal'),
          this.crypto.decrypt(localStorage.getItem('empresa')));
    return this.http.post<{ result: Status[] }>(BACKEND_URL + '/consulta', {
      sql, db: this.database
    });
  }

  generateTopDez(): any {
    const sql =
      localStorage.getItem('opcao') === 'os'
        ? this.sqlList.topDezOSSQL(localStorage.getItem('dataInicial'),
          localStorage.getItem('dataFinal'),
          this.crypto.decrypt(localStorage.getItem('empresa')))
        : this.sqlList.topDezNDSQL(localStorage.getItem('dataInicial'),
          localStorage.getItem('dataFinal'),
          this.crypto.decrypt(localStorage.getItem('empresa')));
    return this.http.post<{ result: TopDez[] }>(BACKEND_URL + '/consulta', {
      sql, db: this.database
    });
  }

  generatEstados(): any {
    const sql =
      localStorage.getItem('opcao') === 'os'
        ? this.sqlList.estadosOSSQL(localStorage.getItem('dataInicial'),
          localStorage.getItem('dataFinal'),
          this.crypto.decrypt(localStorage.getItem('empresa')))
        : this.sqlList.estadosNDSQL(localStorage.getItem('dataInicial'),
          localStorage.getItem('dataFinal'),
          this.crypto.decrypt(localStorage.getItem('empresa')));
    return this.http.post<{ result: Status[] }>(BACKEND_URL + '/consulta', {
      sql, db: this.database
    });
  }

  generatAll(): any {
    const sql =
      localStorage.getItem('opcao') === 'os'
        ? this.sqlList.allOSSQL(localStorage.getItem('dataInicial'),
          localStorage.getItem('dataFinal'),
          this.crypto.decrypt(localStorage.getItem('empresa')))
        : this.sqlList.allNDSQL(localStorage.getItem('dataInicial'),
          localStorage.getItem('dataFinal'),
          this.crypto.decrypt(localStorage.getItem('empresa')));
    return this.http.post<{ result: Status[] }>(BACKEND_URL + '/consulta', {
      sql, db: this.database
    });
  }

  getLabels(): any {
    const sql = this.sqlList.labelsSQL();
    return this.http.post<{ result: string[] }>(BACKEND_URL + '/consulta', {
      sql, db: this.database
    });
  }

  getApelidos(empresa: string): any {
    const sql = this.sqlList.nomesEmpresasSQL(empresa);
    return this.http.post<{ result: Status[] }>(BACKEND_URL + '/consulta', {
      sql, db: this.database
    });
  }

  generateStatusValores(): any {
    const sql =
      localStorage.getItem('opcao') === 'os'
        ? this.sqlList.statusValoresOSSQL(localStorage.getItem('dataInicial'),
          localStorage.getItem('dataFinal'),
          this.crypto.decrypt(localStorage.getItem('empresa')))
        : this.sqlList.statusValoresNDSQL(localStorage.getItem('dataInicial'),
          localStorage.getItem('dataFinal'),
          this.crypto.decrypt(localStorage.getItem('empresa')));
    return this.http.post<{ result: StatusValor[] }>(BACKEND_URL + '/consulta', {
      sql, db: this.database
    });
  }

  generateTop10Placa(): any {
    const sql =
      localStorage.getItem('opcao') === 'os'
        ? this.sqlList.top10PlacaOSSQL(localStorage.getItem('dataInicial'),
          localStorage.getItem('dataFinal'),
          this.crypto.decrypt(localStorage.getItem('empresa')))
        : this.sqlList.top10PlacaNDSQL(localStorage.getItem('dataInicial'),
          localStorage.getItem('dataFinal'),
          this.crypto.decrypt(localStorage.getItem('empresa')));
    return this.http.post<{ result: Status[] }>(BACKEND_URL + '/consulta', {
      sql, db: this.database
    });
  }

  generateTop10Unidade(): any {
    const sql =
      localStorage.getItem('opcao') === 'os'
        ? this.sqlList.top10UnidadeOSSQL(localStorage.getItem('dataInicial'),
          localStorage.getItem('dataFinal'),
          this.crypto.decrypt(localStorage.getItem('empresa')))
        : this.sqlList.top10UnidadeNDSQL(localStorage.getItem('dataInicial'),
          localStorage.getItem('dataFinal'),
          this.crypto.decrypt(localStorage.getItem('empresa')));

    return this.http.post<{ result: Status[] }>(BACKEND_URL + '/consulta', {
      sql, db: this.database
    });
  }

  generateTop10Corretivas(): any {
    const sql =
      localStorage.getItem('opcao') === 'os'
        ? this.sqlList.top10CorretivasOSSQL(localStorage.getItem('dataInicial'),
          localStorage.getItem('dataFinal'),
          this.crypto.decrypt(localStorage.getItem('empresa')))
        : this.sqlList.top10CorretivasNDSQL(localStorage.getItem('dataInicial'),
          localStorage.getItem('dataFinal'),
          this.crypto.decrypt(localStorage.getItem('empresa')));

    return this.http.post<{ result: Status[] }>(BACKEND_URL + '/consulta', {
      sql, db: this.database
    });
  }

  generateTop10Preventivas(): any {
    const sql =
      localStorage.getItem('opcao') === 'os'
        ? this.sqlList.top10PreventivasOSSQL(localStorage.getItem('dataInicial'),
          localStorage.getItem('dataFinal'),
          this.crypto.decrypt(localStorage.getItem('empresa')))
        : this.sqlList.top10PreventivasNDSQL(localStorage.getItem('dataInicial'),
          localStorage.getItem('dataFinal'),
          this.crypto.decrypt(localStorage.getItem('empresa')));

    return this.http.post<{ result: Status[] }>(BACKEND_URL + '/consulta', {
      sql, db: this.database
    });
  }

  generateEconomia(): any {
    const sql =
      localStorage.getItem('opcao') === 'os'
        ? this.sqlList.economiaOSSQL(localStorage.getItem('dataInicial'),
          localStorage.getItem('dataFinal'),
          this.crypto.decrypt(localStorage.getItem('empresa')))
        : this.sqlList.economiaNDSQL(localStorage.getItem('dataInicial'),
          localStorage.getItem('dataFinal'),
          this.crypto.decrypt(localStorage.getItem('empresa')));
    return this.http.post<{ result: Status[] }>(BACKEND_URL + '/consulta', {
      sql, db: this.database
    });
  }

  generateTCO(dataInicial: string, dataFinal: string, empresa: string): any {
    const sql = this.sqlList.TCOSQL(dataInicial, dataFinal, empresa);
    return this.http.post<{ result: Status[] }>(BACKEND_URL + '/consulta', {
      sql, db: this.database
    });
  }

  getLabelsR(): Observable<any> {
    const sql = this.sqlList.labelsSQLR();
    return this.http.post<{ result: string[] }>(BACKEND_URL + '/consulta', {sql, db: this.database});
  }

  consultaBanco(sql: string): any {
    return this.http.post<{ result: string[] }>(BACKEND_URL + '/consulta', {sql, db: this.database});
  }

  getCubosIC(codigofun: string): Observable<any> {
    const sql = this.sqlList.getCubosIC(codigofun);
    return this.http.post<{ result: string[] }>(BACKEND_URL + '/consulta', {sql, db: this.database});
  }

  getLabelCubosIc(codigocubo: string): Observable<any> {
    const sql = this.sqlList.getLabelCubosIc(codigocubo);
    return this.http.post<{ result: string[] }>(BACKEND_URL + '/consulta', {sql, db: this.database});
  }

}
