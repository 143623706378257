import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AESEncryptDecryptService } from '../../AESEncryptDecrypt.service';

import { MultasSQL } from './infracoes.sql';
import { Status } from '../atendimento/status.model';
import { Observable } from 'rxjs';

const BACKEND_URL = environment.apiUrl + '/consulta';

@Injectable({ providedIn: 'root' })
export class MultasService {

  database = localStorage.getItem('gerencial_db');

  constructor(private http: HttpClient,
              private sqlList: MultasSQL,
              private crypto: AESEncryptDecryptService) {}

  getTipos(): any {
    const sql = this.sqlList.getTipoSQL(this.crypto.decrypt(localStorage.getItem('empresa')) || null,
                                        localStorage.getItem('dataInicial') || null || null,
                                        localStorage.getItem('dataFinal') || null || null);
    return this.http.post<{ result: any[] }>(BACKEND_URL + '/label', {sql, db: this.database});
  }

  getTipos2(): any {
    const sql = this.sqlList.getTipoSQL(this.crypto.decrypt(localStorage.getItem('empresa')) || null,
                                        localStorage.getItem('dataInicial') || null,
                                        localStorage.getItem('dataFinal') || null);
    return this.http.post<{ result: any[] }>(BACKEND_URL + '/consulta', {sql, db: this.database});
  }

  getResumoGravidade(dias: number): any {
    const sql = this.sqlList.getResumoGravidade(dias || 365, this.crypto.decrypt(localStorage.getItem('empresa')) || null);
    return this.http.post<{ result: Status[] }>(BACKEND_URL + '/consulta', {sql, db: this.database});
  }

  getGravidade(): any {
    const sql = this.sqlList.getGravidadeSQL(this.crypto.decrypt(localStorage.getItem('empresa')) || null,
                                              localStorage.getItem('dataInicial') || null,
                                              localStorage.getItem('dataFinal') || null);
    return this.http.post<{ result: any[] }>(BACKEND_URL + '/consulta', {sql, db: this.database});
  }

  getDescricao(): any {
    const sql = this.sqlList.getDescricaoSQL(this.crypto.decrypt(localStorage.getItem('empresa')) || null,
                                              localStorage.getItem('dataInicial') || null,
                                              localStorage.getItem('dataFinal') || null);
    return this.http.post<{ result: any[] }>(BACKEND_URL + '/consulta', {sql, db: this.database});
  }

  getMensal(): any {
    const sql = this.sqlList.getMensalSQL(this.crypto.decrypt(localStorage.getItem('empresa')) || null,
                                          localStorage.getItem('dataInicial') || null,
                                          localStorage.getItem('dataFinal') || null);
    return this.http.post<{ result: any[] }>(BACKEND_URL + '/consulta', {sql, db: this.database});
  }

  getTop10(): any {
    const sql = this.sqlList.getTop10(this.crypto.decrypt(localStorage.getItem('empresa')) || null,
                                      localStorage.getItem('dataInicial') || null,
                                      localStorage.getItem('dataFinal') || null);
    return this.http.post<{ result: any[] }>(BACKEND_URL + '/consulta', {sql, db: this.database});
  }

  getGravissimas(): any {
    const sql = this.sqlList.getGravissima(this.crypto.decrypt(localStorage.getItem('empresa')) || null,
                                            localStorage.getItem('dataInicial') || null,
                                            localStorage.getItem('dataFinal') || null);
    return this.http.post<{ result: any[] }>(BACKEND_URL + '/consulta', {sql, db: this.database});
  }

  getEstados(): any {
    const sql = this.sqlList.getEstados(this.crypto.decrypt(localStorage.getItem('empresa')) || null,
                                        localStorage.getItem('dataInicial') || null,
                                        localStorage.getItem('dataFinal') || null);
    return this.http.post<{ result: any[] }>(BACKEND_URL + '/consulta', {sql, db: this.database});
  }

  generatAll(): any {
    const sql = this.sqlList.allSQL(localStorage.getItem('dataInicial') || null,
                                    localStorage.getItem('dataFinal') || null,
                                    this.crypto.decrypt(localStorage.getItem('empresa')) || null);
    return this.http.post<{ result: Status[] }>(BACKEND_URL + '/consulta',  {sql, db: this.database});
  }

  getLabels(): any {
    const sql = this.sqlList.labelsSQL();
    return this.http.post<{ result: string[] }>(BACKEND_URL + '/consulta',  {sql, db: this.database});
  }

  getMultasValores(): any {
    const sql = this.sqlList.getValorTipoSQL(this.crypto.decrypt(localStorage.getItem('empresa')) || null,
                                      localStorage.getItem('dataInicial') || null,
                                      localStorage.getItem('dataFinal') || null);
    return this.http.post<{ result: any[] }>(BACKEND_URL + '/consulta', { sql, db: this.database});
  }

  getTop10PlacasValores(): any {
    const sql = this.sqlList.getTop10PlacaValorSQL(this.crypto.decrypt(localStorage.getItem('empresa')) || null,
                                      localStorage.getItem('dataInicial') || null,
                                      localStorage.getItem('dataFinal') || null);
    return this.http.post<{ result: any[] }>(BACKEND_URL + '/consulta', { sql, db: this.database });
  }

  getTop10ClassificacaoValores(): any {
    const sql = this.sqlList.getTop10ClassificacaoValorSQL(this.crypto.decrypt(localStorage.getItem('empresa')) || null,
                                      localStorage.getItem('dataInicial') || null,
                                      localStorage.getItem('dataFinal') || null);
    return this.http.post<{ result: any[] }>(BACKEND_URL + '/consulta', { sql, db: this.database });
  }

  getLabelsR(): Observable<any> {
    const sql = this.sqlList.labelsSQLR();
    return this.http.post<{ result: string[] }>(BACKEND_URL + '/consulta', {sql, db: this.database});
  }

}
