import { IcExpressComponent } from './painel/content/ic-express/ic-express.component';
import { GeradorRelatorioGerencialComponent } from './painel/content/gerador-relatorio-gerencial/gerador-relatorio-gerencial.component';
import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { PainelComponent } from './painel/painel.component';
import { HomeComponent } from './painel/content/home/home.component';
import { AtendimentoComponent } from './painel/content/atendimento/atendimento.component';
import { ServicosComponent } from './painel/content/servicos/servicos.component';
import { FrotaComponent } from './painel/content/frota/frota.component';
import { CustoComponent } from './painel/content/custo/custo.component';
import { InfracoesComponent } from './painel/content/infracoes/infracoes.component';
import { AuthGuard } from './login/login.guard';
import { RelatorioRapidoComponent } from './painel/relatorio-rapido/relatorio-rapido.component';

export const ROUTES: Routes = [
    {path: '', component: LoginComponent },
    {path: 'login', component: LoginComponent },
    {path: 'painel', component: PainelComponent, canActivate: [AuthGuard], children: [
        {path: '', component: HomeComponent, canActivate: [AuthGuard]},
        {path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
        {path: 'atendimento', component: AtendimentoComponent, canActivate: [AuthGuard]},
        {path: 'servicos', component: ServicosComponent, canActivate: [AuthGuard]},
        {path: 'frota', component: FrotaComponent, canActivate: [AuthGuard]},
        {path: 'custo', component: CustoComponent, canActivate: [AuthGuard]},
        {path: 'infracoes', component: InfracoesComponent, canActivate: [AuthGuard]},
        {path: 'rapido', component: RelatorioRapidoComponent, canActivate: [AuthGuard]},
        {path: 'gerencial', component: GeradorRelatorioGerencialComponent, canActivate: [AuthGuard]},
        {path: 'icExpress', component: IcExpressComponent, canActivate: [AuthGuard]},
    ] },
  ];
