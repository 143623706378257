import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChartModel } from '../../../charts.model';
import { ServicosService } from '../../servicos/servicos.service';
import { MultasService } from '../../infracoes/infracoes.service';
import { FrotaService } from '../../frota/frota.service';

@Component({
  selector: 'pt-all-servicos',
  templateUrl: './allServicos.component.html',
  styleUrls: ['./allServicos.component.css'],
})
export class AllServicosComponent implements OnInit {
  thisDataTable: ChartModel = {
    data: null,
    type: null,
    options: null,
    names: null,
  };

  allData: any = [];
  backupData: any = [];
  labels: any = [];

  progress = 0;

  loading = true;

  // CSV
  csvData = [];

  CSVoptions = {
    fieldSeparator: ';',
    quoteStrings: '"',
    decimalseparator: ',',
    headers: this.labels,
    showTitle: true,
    title: 'Informações sobre ' + this.data.tipo,
    useBom: true,
    removeNewLines: false,
    keys: []
  };

  constructor(
    private servicosService: ServicosService,
    private infracaoService: MultasService,
    private frotaService: FrotaService,
    private dialogRef: MatDialogRef<AllServicosComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      tipo: string
    }
  ) {}

  ngOnInit(): void {
    this.geraDados();
  }

  generateThisTable(): void {
    const newLabel = [];

    for (const lab of this.labels){
      newLabel.push(lab.toString());
    }

    this.thisDataTable.data = this.allData;
    this.thisDataTable.names = newLabel;
    this.thisDataTable.type = 'Table';
    this.thisDataTable.options = {
      alternatingRowStyle: true,
      chartArea: { width: '90%', maxheight: '50vh' },
      backgroundColor: 'transparent',
      position: 'center',
      showRowNumber: true,
      page: 'enable',
      pageSize: 4,
      pagingSymbols: {
        prev: '   <   ',
        next: '   >   ',
      },
      pagingButtonsConfiguration: 'auto',
    };
  }

  onClose(): void {
    this.dialogRef.close();
  }

  geraDados(): void{
    const interval = setInterval(() => {
      this.progress < 98
        ? (this.progress += 0.5)
        : (this.progress = this.progress);
    }, 50);

    // se for OS
    if (this.data.tipo === 'os'){
      this.servicosService.generatAll().subscribe((allData) => {
        this.allData = allData.result;
        this.progress = 95;
        // get labels
        this.servicosService.getLabels().subscribe((labels) => {
            this.labels = labels.result;
            this.backupData = allData.result;
            this.loading = false;
            clearInterval(interval);
            this.generateThisTable();
            this.CSVdataGenerator();
          },
          (erro) => {
            console.log(erro);
          }
        );
      },
      (erro) => {
        console.log(erro);
      }
    ); // se for multas
    } else if (this.data.tipo === 'infracao') {
      this.infracaoService.generatAll().subscribe((allData) => {
        this.allData = allData.result;
        this.backupData = allData.result;
        this.progress = 95;
        // get labels
        this.infracaoService.getLabels().subscribe((labels) => {
            this.labels = labels.result;
            this.loading = false;
            clearInterval(interval);
            this.generateThisTable();
            this.CSVdataGenerator();
          },
          (erro) => {
            console.log(erro);
          }
        );
      },
      (erro) => {
        console.log(erro);
      }
    ); // se frota
    } else if (this.data.tipo === 'frota') {
      this.frotaService.generatAll().subscribe((allData) => {
        this.allData = allData.result;
        this.backupData = allData.result;
        this.progress = 95;
        // get labels
        this.frotaService.getLabels().subscribe((labels) => {
            this.labels = labels.result;
            this.loading = false;
            clearInterval(interval);
            this.generateThisTable();
            this.CSVdataGenerator();
          },
          (erro) => {
            console.log(erro);
          }
        );
      },
      (erro) => {
        console.log(erro);
      }
    );
    }
  }

  CSVdataGenerator(): void {
    this.csvData = [];
    this.CSVoptions.headers = this.labels;
    const clone = this.backupData;

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < clone.length; i++){
      for (let j = 0; j < clone[i].length; j++){
        if (clone[i][j] === '' || clone[i][j] === null ||
            // tslint:disable-next-line: triple-equals
            clone[i][j].toString().length <= 0 || clone[i][j] == 0) {
          clone[i][j] = '-';
        }
      }
      this.csvData.push(clone[i]);
    }
  }



}
